@import 'variables';
.shared-radio {
  display: flex;
  align-items: center;
  .radio {
    visibility: hidden;
    width: $sizeEmpty + 4;
    height: $sizeEmpty + 4;
    &:before {
      visibility: visible;
      font-family: $fontFamilyIconRadio;
      font-size: $sizeEmpty;
      content: $iconEmptyRadio;
      position: absolute;
      color: $colorRadio;
    }
    &:checked {
      &:after {
        visibility: visible;
        font-family: $fontFamilyIconRadio;
        content: $iconCheckRadio;
        position: absolute;
        font-size: $sizeRadio;
        color: $colorRadio;
        top: 8px;
        left: 13px;
      }
    }
  }
}
