@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?4f45vi');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?4f45vi#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?4f45vi')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?4f45vi')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?4f45vi##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert-circle {
  &:before {
    content: $icon-alert-circle;
  }
}
.icon-export {
  &:before {
    content: $icon-export;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye-off {
  &:before {
    content: $icon-eye-off;
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text;
  }
}
.icon-grid {
  &:before {
    content: $icon-grid;
  }
}
.icon-key {
  &:before {
    content: $icon-key;
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.icon-shield {
  &:before {
    content: $icon-shield;
  }
}
.icon-shield-off {
  &:before {
    content: $icon-shield-off;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-upload-f {
  &:before {
    content: $icon-upload-f;
  }
}
.icon-list-file {
  &:before {
    content: $icon-list-file;
  }
}
.icon-file-xml {
  &:before {
    content: $icon-file-xml;
  }
}
.icon-arrow-paglast {
  &:before {
    content: $icon-arrow-paglast;
    color: #d1d1d6;
  }
}
.icon-arrow-pagfirst {
  &:before {
    content: $icon-arrow-pagfirst;
    color: #d1d1d6;
  }
}
.icon-arrow-pagright {
  &:before {
    content: $icon-arrow-pagright;
    color: #bfbfbf;
  }
}
.icon-arrow-pagleft {
  &:before {
    content: $icon-arrow-pagleft;
    color: #bfbfbf;
  }
}
.icon-cal-annual {
  &:before {
    content: $icon-cal-annual;
  }
}
.icon-cal-trim {
  &:before {
    content: $icon-cal-trim;
  }
}
.icon-cal-month {
  &:before {
    content: $icon-cal-month;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-confirm {
  &:before {
    content: $icon-confirm;
  }
}
.icon-sort {
  &:before {
    content: $icon-sort;
  }
}
.icon-delete-profile {
  &:before {
    content: $icon-delete-profile;
    color: #445167;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
    color: #445167;
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle;
  }
}
.icon-moreV {
  &:before {
    content: $icon-moreV;
  }
}
.icon-history {
  &:before {
    content: $icon-history;
    color: #241d8c;
  }
}
.icon-inputDown {
  &:before {
    content: $icon-inputDown;
    color: #445167;
  }
}
.icon-inputUp {
  &:before {
    content: $icon-inputUp;
    color: #445167;
  }
}
.icon-selectorCheck {
  &:before {
    content: $icon-selectorCheck;
    color: #7ed321;
  }
}
.icon-selectorUncheck {
  &:before {
    content: $icon-selectorUncheck;
    color: #445167;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
    color: #a10000;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-covip {
  &:before {
    content: $icon-covip;
  }
}
.icon-down {
  &:before {
    content: $icon-down;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-schema {
  &:before {
    content: $icon-schema;
  }
}
.icon-setting {
  &:before {
    content: $icon-setting;
  }
}
.icon-up {
  &:before {
    content: $icon-up;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
