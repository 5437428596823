$errorInput: #a10000 !default;

$widthInput: 100% !default;

$heightInput: 38px !default;
$heightMediumInput: 36px !default;
$heightSmallInput: 30px !default;

$fontFamilyInput: 'Roboto-regular' !default;

$fontSizeInput: 16px !default;
$fontSizeMediumInput: 16px !default;
$fontSizeSmallInput: 16px !default;

$lineheightMediumInput: 24px !default;
$lineheightSmallInput: 19px !default;

$borderRadiusInput: 4px !default;
