@import 'font';
@import './icon/style';
@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '../components/components';
@import './features';

.main-auth {
  margin-top: 140px;
  @media screen and (max-width: 990px) {
    margin-top: 120px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 120px;
  }
}

.pointer {
  cursor: pointer;
}
