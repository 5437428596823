@import 'variables';

.shared-accordion {
  .title {
    position: absolute;
    top: -$heightTitleAccordion;
    left: 0px;
    display: flex;
    align-items: center;
    background: $bgTitleAccordion;
    color: $colorTitleAccordion;
    text-transform: uppercase;
    font-size: $fontSizeTitleAccordion;
    letter-spacing: $letterSapcingTitleAccordion;
    height: $heightTitleAccordion;
    min-width: $minWidthTitleAccordion;
    line-height: initial;
    border-top-left-radius: $borderRadiusTitleAccordion;
    border-top-right-radius: $borderRadiusTitleAccordion;
    border-bottom-left-radius: $borderRadiusTitleAccordion;
    border-bottom-right-radius: $borderRadiusTitleAccordion;
    max-width: 100%;
    width: 100%;
    & span {
      width: 100%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 900;
    }
    & .number {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: right;
      background-color: #fff;
      color: #2b2e32;
      width: auto;
      min-width: 20px;
      border-radius: 2px;
      padding: 4px 12px;
      margin-left: 6px;
    }
    &::after {
      font-family: $fontFamilyIconDownAccordion;
      content: $iconDownAccordion;
      font-size: $fontSizeIconDownAccordion;
      margin-left: 16px;
    }
    &.show {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
  &:not(.not-padding) {
    .collapse {
      padding: 1.25rem;
    }
  }
  .collapse {
    border: 1px solid #0000001a;
  }
}
.box {
  & .card-body {
    min-height: 0px;
    padding: 0px;
  }
}
