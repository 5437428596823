$colorBgSwitchOn: #3a80bb !default;
$colorSwitchOn: #fff !default;
$colorSwitchOff: #fff !default;
$colorBgSwitchOff: #abadb7 !default;

$colorSwitchError: #a10000 !default;

$widthCircle: 18px !default;
$widthSwitch: $widthCircle * 2+8 !default;
$heightSwitch: $widthCircle + 4 !default;
$radiusSwitch: $widthCircle/2 + 2 !default;

$positionOn: -$heightSwitch - 2 !default;
