$overListItem: #f5f5f5;

.load-list {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;

  li {
    margin: 0 0 6px 0px;
    position: relative;
    list-style: none;
    font-weight: bold;

    &:hover {
      border-radius: 4px;
      background-color: $overListItem;

      .icon.left {
        color: #666;
        -webkit-animation: slideRight 100ms ease-in-out forwards;
        -moz-animation: slideRight 100ms ease-in-out forwards;
        -ms-animation: slideRight 100ms ease-in-out forwards;
        animation: slideRight 100ms ease-in-out forwards;
      }
    }

    .icon.left {
      display: block;
      color: #666;
      font-size: 18px;
      position: absolute;
      left: 6px;
    }
  }
}

@-webkit-keyframes slideRight {
  from {
    left: 6px;
  }
  to {
    left: 16px;
  }
}
@-moz-keyframes slideRight {
  from {
    left: 6px;
  }
  to {
    left: 16px;
  }
}
@-ms-keyframes slideRight {
  from {
    left: 6px;
  }
  to {
    left: 16px;
  }
}
@keyframes slideRight {
  from {
    left: 6px;
  }
  to {
    left: 16px;
  }
}
