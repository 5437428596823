$errorInputSelect: #a10000 !default;

$widthInputSelect: 100% !default;

$heightInputSelect: 38px !default;
$heightMediumInputSelect: 36px !default;
$heightSmallInputSelect: 30px !default;

$fontFamilyInputSelect: 'Roboto' !default;

$fontSizeInputSelect: 16px !default;
$fontSizeMediumInputSelect: 16px !default;
$fontSizeSmallInputSelect: 16px !default;

$lineheightMediumInputSelect: 24px !default;
$lineheightSmallInputSelect: 19px !default;

$borderRadiusInputSelect: 4px !default;
$borderColorInputSelect: #3759e3 !default;
