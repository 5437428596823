@import 'variables';

.navbar {
  &.navGradient {
    background-image: $bgGradientPrimary;
    min-height: $navbarHeight;
    .logoBrand {
      height: 62px;
      cursor: pointer;
      margin-top: 0px;
    }
    .menuItems {
      .nav-item,
      .nav-link {
        color: $colorWhite;
        font-size: 14px;
        text-transform: uppercase;
        font-family: 'Roboto-bold';
        margin: 0px 10px;
        @media screen and (max-width: 1024px) {
          margin: 0px;
        }
        &.active {
          opacity: 0.8;
        }
        .dropdown-toggle {
          &::after {
            content: none;
          }
        }
      }
      .dropdown-menu {
        @media screen and (max-width: 1024px) {
          width: auto;
          background-color: #fff;
          padding-top: 8px;
          border-bottom: 4px solid #7ed321;
          .dropdown-item {
            display: flex;
            align-items: center;
            color: #445167d9;
            font-family: 'Roboto-bold';
            font-size: 16px;
            height: 44px;
            border-bottom: 1px solid #f5f6f9;
            border-left: 4px solid transparent;
            text-transform: capitalize;
            &:last-child {
              border-bottom: none;
            }
            &:active,
            &.active,
            &:hover {
              background-color: #f5f6f9;
              border-left: 4px solid #7ed321;
            }
            &:hover {
              opacity: 0.85;
            }
          }
        }
        @media screen and (min-width: 1024px) {
          width: 290px;
          background-color: #fff;
          padding-top: 32px;
          right: auto;
          left: -4px;
          border-bottom: 4px solid #7ed321;
          &::before {
            left: 32px;
            content: '\25B2';
            position: absolute;
            top: -16px;
            color: #fff;
          }
          .dropdown-item {
            display: flex;
            align-items: center;
            color: #445167d9;
            font-family: 'Roboto-bold';
            font-size: 16px;
            height: 44px;
            border-bottom: 1px solid #f5f6f9;
            border-left: 4px solid transparent;
            text-transform: capitalize;

            &:last-child {
              border-bottom: none;
            }
            &:active,
            &.active,
            &:hover {
              background-color: #f5f6f9;
              border-left: 4px solid #7ed321;
            }
            &:hover {
              opacity: 0.85;
            }
          }
        }
      }
    }

    .menuLogged {
      align-items: center;
      > .userImg {
        border-left: 1px solid #ffffff99;
        padding-left: 20px;
      }
      > .icon {
        &.icon-setting {
          display: flex;
          align-items: center;
          padding-right: 20px;
          color: $colorWhite;
          font-size: 22px;
        }
      }
      .dropdown {
        .dropdown-toggle {
          &.nav-link {
            display: flex;
            font-size: 18px;
            font-family: 'Roboto-regular';
            color: $colorWhite;
            padding-left: 14px;
            &::after {
              margin-left: 20px;
              content: '\e904';
              font-family: 'onewelf';
              font-optical-sizing: 24px;
              transform: rotate(90deg);
              border: none;
            }
          }
        }
        &.manageUsers {
          .dropdown-toggle {
            &.nav-link {
              margin-right: 12px;
              &::after {
                content: initial;
              }
            }
          }
        }

        .dropdown-menu {
          @media screen and (min-width: 990px) {
            width: 290px;
            background-color: #241d8c;
            padding-top: 32px;
            right: -12px;
            left: auto;

            &::before {
              right: 32px;
              content: '\25B2';
              position: absolute;
              top: -16px;
              color: #241d8c;
            }
            .dropdown-item {
              display: flex;
              align-items: center;
              color: #fff;
              font-family: 'Roboto-bold';
              font-size: 16px;
              height: 44px;
              border-bottom: 1px solid #aaa;
              border-left: 4px solid transparent;

              &:last-child {
                border-bottom: none;
              }
              &:active,
              &.active,
              &:hover {
                background-color: #241d8c;
                border-left: 4px solid #7ed321;
              }
              &:hover {
                opacity: 0.85;
              }
            }
          }
        }
      }
    }

    .visible-md {
      display: none;
    }
    @media screen and (max-width: 990px) {
      .menuLogged {
        .label-icon {
          font-family: 'Roboto-bold';
          text-transform: uppercase;
          font-size: 14px;
        }
        .visible-md {
          display: inline;
        }
        .hide-md {
          display: none !important;
        }
      }
      .menuLogged {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .manageUsers {
          .dropdown-menu {
            position: absolute;
            left: -8px;
          }
        }
        .manageProfile {
          .dropdown-menu {
            position: absolute;
            left: -20px;
          }
        }
      }
    }
  }
}
