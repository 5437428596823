$bgTitleAccordion: #0393f3 !default;
$colorTitleAccordion: #fff !default;
$heightTitleAccordion: 32px !default;
$fontSizeTitleAccordion: 14px !default;
$letterSapcingTitleAccordion: 1px !default;
$borderRadiusTitleAccordion: 4px !default;
$minWidthTitleAccordion: 250px !default;

$fontFamilyIconDownAccordion: 'onewelf' !default;
$fontSizeIconDownAccordion: 10px !default;
$iconDownAccordion: '\e900' !default;
