@import '../../components/shared/_mediaQuery.scss';

.searchBar {
  @media (max-width: $sm-device) {
    flex-direction: column;
  }
  & .shared-label {
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
  }
  & .secondFilter {
    min-width: fit-content;
    max-width: fit-content;
    @media (max-width: $sm-device) {
      min-width: 100%;
    }
  }
}
