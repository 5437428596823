.dx-device-mobile .dx-toolbar {
  padding: 0;
  overflow: visible;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-before {
  padding-right: 15px;
}
.dx-rtl.dx-device-mobile .dx-toolbar .dx-toolbar-before {
  padding-right: 0;
  padding-left: 15px;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-after {
  padding-left: 15px;
}
.dx-rtl.dx-device-mobile .dx-toolbar .dx-toolbar-after {
  padding-left: 0;
  padding-right: 15px;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-after:empty,
.dx-device-mobile .dx-toolbar .dx-toolbar-before:empty {
  padding: 0;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-items-container {
  height: 36px;
  overflow: visible;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-menu-container {
  padding: 0 0 0 5px;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-item,
.dx-rtl.dx-device-mobile .dx-toolbar .dx-toolbar-menu-container {
  padding: 0 5px 0 0;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-item.dx-toolbar-first-in-group {
  padding-left: 20px;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-rtl.dx-device-mobile .dx-toolbar .dx-toolbar-item {
  padding: 0 0 0 5px;
}
.dx-rtl.dx-device-mobile .dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-device-mobile .dx-toolbar .dx-toolbar-label {
  font-size: 20px;
}
.dx-toolbar.dx-state-disabled {
  opacity: 1;
}
.dx-toolbar.dx-toolbar-compact .dx-button {
  min-width: auto;
}
.dx-toolbar-after .dx-toolbar-item,
.dx-toolbar-after .dx-toolbar-item:last-child {
  padding: 0 0 0 5px;
}
.dx-toolbar-after .dx-toolbar-item:first-child {
  padding: 0;
}
.dx-rtl.dx-toolbar-after .dx-toolbar-item:first-child {
  padding-left: 5px;
}
.dx-device-mobile .dx-toolbar-after .dx-toolbar-item,
.dx-device-mobile .dx-toolbar-after .dx-toolbar-item:last-child {
  padding: 0 0 0 5px;
}
.dx-device-mobile .dx-toolbar-after .dx-toolbar-item:first-child {
  padding: 0;
}
.dx-rtl.dx-device-mobile .dx-toolbar-after .dx-toolbar-item:first-child {
  padding-left: 5px;
}
.dx-toolbar-background {
  background-color: #fff;
}
.dx-toolbar-menu-section {
  border-bottom: 1px solid #d0e0ed;
}
.dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button-content,
.dx-toolbar-menu-section .dx-toolbar-item-auto-hide .dx-button-content {
  padding: 0;
}
.dx-toolbar-menu-section .dx-button-content {
  padding: 4px;
}
.dx-toolbar-menu-section .dx-toolbar-item-auto-hide {
  padding: 5px 10px;
}
.dx-toolbar-text-auto-hide .dx-button .dx-button-content {
  padding: 8px;
}
.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin-right: 0;
  margin-left: 0;
}
.dx-rtl .dx-toolbar-text-auto-hide .dx-button .dx-icon,
.dx-rtl.dx-toolbar-text-auto-hide .dx-button .dx-icon,
.dx-toolbar-text-auto-hide .dx-button .dx-icon.dx-icon-right {
  margin-left: 0;
  margin-right: 0;
}
.dx-rtl .dx-toolbar-text-auto-hide .dx-button .dx-icon.dx-icon-right,
.dx-rtl.dx-toolbar-text-auto-hide .dx-button .dx-icon.dx-icon-right {
  margin-right: 0;
  margin-left: 0;
}
.dx-toolbar .dx-tab {
  padding: 4px;
}
.dx-toolbar-button .dx-menu .dx-menu-item {
  border: 1px solid transparent;
  border-radius: 4px;
}
.dx-toolbar-button .dx-menu .dx-menu-item .dx-menu-item-content {
  padding-top: 7px;
  padding-bottom: 8px;
  line-height: 0;
}
.dx-toolbar-button
  .dx-menu
  .dx-menu-item
  .dx-menu-item-content
  .dx-menu-item-text {
  line-height: normal;
}
.dx-toolbar-hidden-button-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
.dx-toolbar-hidden-button-group
  .dx-buttongroup-wrapper
  .dx-buttongroup-item.dx-button
  .dx-button-content {
  padding: 5px 10px;
}
.dx-popup-draggable .dx-popup-title {
  cursor: move;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-overlay-content > .dx-template-wrapper.dx-popup-title {
  height: auto;
  width: auto;
}
.dx-overlay-content .dx-popup-content > .dx-template-wrapper {
  height: 100%;
  width: 100%;
}
.dx-overlay-content .dx-popup-content {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-popup-flex-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dx-overlay-wrapper .dx-overlay-content.dx-popup-flex-height > .dx-popup-bottom,
.dx-overlay-wrapper .dx-overlay-content.dx-popup-flex-height > .dx-popup-title {
  width: 100%;
}
.dx-popup-flex-height .dx-popup-content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 100%;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.dx-popup-inherit-height .dx-popup-content > .dx-scrollable,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-scrollable
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-scrollable
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-popup-inherit-height .dx-popup-content > .dx-treeview > .dx-scrollable,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-treeview
  > .dx-scrollable
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-treeview
  > .dx-scrollable
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-treeview
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-treeview
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container {
  max-height: inherit;
  min-height: inherit;
}
.dx-popup-inherit-height .dx-popup-content > .dx-widget {
  max-height: inherit;
}
.dx-popup-inherit-height .dx-popup-content > .dx-template-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-scrollable,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-scrollable
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-scrollable
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-treeview
  > .dx-scrollable,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-treeview
  > .dx-scrollable
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-treeview
  > .dx-scrollable
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-treeview
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper,
.dx-popup-inherit-height
  .dx-popup-content
  > .dx-template-wrapper
  > .dx-treeview
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container {
  max-height: inherit;
  min-height: inherit;
}
.dx-popup-inherit-height .dx-popup-content > .dx-template-wrapper > .dx-widget {
  max-height: inherit;
}
.dx-dialog-root .dx-overlay-shader {
  background-color: #444;
}
.dx-prevent-safari-scrolling {
  position: fixed;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
}
.dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #d0e0ed;
  background: #fff;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 6px;
}
.dx-popup-wrapper > .dx-popup-fullscreen {
  border-radius: 0;
}
.dx-popup-title {
  min-height: 19px;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  white-space: normal;
  position: relative;
  padding: 6px 20px;
  min-height: 28px;
  border-bottom: 1px solid #d0e0ed;
  background: 0 0;
  color: rgba(68, 81, 103, 0.85);
}
.dx-popup-title.dx-toolbar {
  padding: 6px 20px;
  overflow: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dx-popup-title.dx-toolbar .dx-toolbar-before {
  padding-right: 15px;
}
.dx-rtl.dx-popup-title.dx-toolbar .dx-toolbar-before {
  padding-right: 0;
  padding-left: 15px;
}
.dx-popup-title.dx-toolbar .dx-toolbar-after {
  padding-left: 15px;
}
.dx-rtl.dx-popup-title.dx-toolbar .dx-toolbar-after {
  padding-left: 0;
  padding-right: 15px;
}
.dx-popup-title.dx-toolbar .dx-toolbar-after:empty,
.dx-popup-title.dx-toolbar .dx-toolbar-before:empty {
  padding: 0;
}
.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 36px;
  overflow: visible;
}
.dx-popup-title.dx-toolbar .dx-toolbar-menu-container {
  padding: 0 0 0 10px;
}
.dx-popup-title.dx-toolbar .dx-toolbar-item,
.dx-rtl.dx-popup-title.dx-toolbar .dx-toolbar-menu-container {
  padding: 0 10px 0 0;
}
.dx-popup-title.dx-toolbar .dx-toolbar-item.dx-toolbar-first-in-group {
  padding-left: 20px;
}
.dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-rtl.dx-popup-title.dx-toolbar .dx-toolbar-item {
  padding: 0 0 0 10px;
}
.dx-rtl.dx-popup-title.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-popup-title.dx-toolbar .dx-toolbar-label {
  font-size: 20px;
}
.dx-popup-title .dx-closebutton {
  display: block;
  border-radius: 4px;
  background: 0 0;
  width: 23px;
  height: 23px;
  margin: 0 -4px 0 4px;
  border: 1px solid transparent;
}
.dx-popup-title .dx-closebutton .dx-button-content {
  padding: 0;
}
.dx-popup-title .dx-closebutton .dx-icon {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 21px;
  height: 21px;
  background-position: 3px 3px;
  background-size: 15px 15px;
  padding: 3px;
  font-size: 15px;
  text-align: center;
  line-height: 15px;
}
.dx-state-disabled .dx-popup-title .dx-closebutton .dx-icon,
.dx-state-disabled.dx-popup-title .dx-closebutton .dx-icon {
  opacity: 0.6;
}
.dx-state-disabled .dx-popup-title .dx-closebutton .dx-button-text,
.dx-state-disabled.dx-popup-title .dx-closebutton .dx-button-text {
  opacity: 0.5;
}
.dx-rtl .dx-popup-title .dx-closebutton {
  margin: 0 4px 0 -4px;
}
.dx-device-mobile .dx-popup-title .dx-closebutton {
  width: 37px;
  height: 37px;
  margin: 0 -11px 0 11px;
}
.dx-rtl .dx-device-mobile .dx-popup-title .dx-closebutton {
  margin: 0 11px 0 -11px;
}
.dx-device-mobile .dx-popup-title .dx-closebutton .dx-icon {
  width: 35px;
  height: 35px;
  background-position: 10px 10px;
  background-size: 15px 15px;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  line-height: 15px;
}
.dx-popup-content {
  -webkit-user-drag: none;
  padding: 20px;
}
.dx-popup-content > .dx-button {
  margin: 0 10px;
}
.dx-popup-bottom {
  background: 0 0;
  color: rgba(68, 81, 103, 0.85);
}
.dx-popup-bottom.dx-toolbar {
  padding: 20px;
  overflow: visible;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-before {
  padding-right: 15px;
}
.dx-rtl.dx-popup-bottom.dx-toolbar .dx-toolbar-before {
  padding-right: 0;
  padding-left: 15px;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  padding-left: 15px;
}
.dx-rtl.dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  padding-left: 0;
  padding-right: 15px;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-after:empty,
.dx-popup-bottom.dx-toolbar .dx-toolbar-before:empty {
  padding: 0;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container {
  height: 36px;
  overflow: visible;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-menu-container {
  padding: 0 0 0 10px;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-item,
.dx-rtl.dx-popup-bottom.dx-toolbar .dx-toolbar-menu-container {
  padding: 0 10px 0 0;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-item.dx-toolbar-first-in-group {
  padding-left: 20px;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-rtl.dx-popup-bottom.dx-toolbar .dx-toolbar-item {
  padding: 0 0 0 10px;
}
.dx-rtl.dx-popup-bottom.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-popup-bottom.dx-toolbar .dx-toolbar-label {
  font-size: 20px;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar {
  padding: 20px;
  overflow: visible;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-before {
  padding-right: 15px;
}
.dx-rtl.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-before {
  padding-right: 0;
  padding-left: 15px;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  padding-left: 15px;
}
.dx-rtl.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  padding-left: 0;
  padding-right: 15px;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-after:empty,
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-before:empty {
  padding: 0;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-items-container {
  height: 36px;
  overflow: visible;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-menu-container {
  padding: 0 0 0 10px;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-item,
.dx-rtl.dx-device-mobile
  .dx-popup-bottom.dx-toolbar
  .dx-toolbar-menu-container {
  padding: 0 10px 0 0;
}
.dx-device-mobile
  .dx-popup-bottom.dx-toolbar
  .dx-toolbar-item.dx-toolbar-first-in-group {
  padding-left: 20px;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-rtl.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-item {
  padding: 0 0 0 10px;
}
.dx-rtl.dx-device-mobile
  .dx-popup-bottom.dx-toolbar
  .dx-toolbar-item:last-child {
  padding: 0;
}
.dx-device-mobile .dx-popup-bottom.dx-toolbar .dx-toolbar-label {
  font-size: 20px;
}
.dx-popup-bottom .dx-button {
  min-width: 100px;
}
.dx-popup-content.dx-dialog-content {
  min-width: 180px;
  padding: 20px;
}
.dx-dialog-message {
  padding: 0;
}
.dx-actionsheet-popup-wrapper .dx-overlay-content {
  padding-top: 0;
  padding-bottom: 0;
  -webkit-user-drag: none;
}
.dx-actionsheet-popover-wrapper .dx-popup-content .dx-button,
.dx-actionsheet-popup-wrapper .dx-popup-content .dx-button {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.dx-actionsheet-cancel,
.dx-actionsheet-item {
  width: 100%;
}
.dx-state-disabled .dx-actionsheet-container .dx-button {
  cursor: default;
}
.dx-actionsheet-popover-wrapper .dx-popup-title,
.dx-actionsheet-popup-wrapper .dx-popup-title {
  word-wrap: break-word;
}
.dx-actionsheet-container .dx-actionsheet-item {
  margin: 0 0 10px;
}
.dx-actionsheet-container .dx-button,
.dx-button.dx-actionsheet-cancel {
  margin: 0;
}
.dx-popover-wrapper .dx-popover-arrow::after {
  width: 14.1422712488px;
  height: 14.1422712488px;
}
.dx-popover-wrapper.dx-position-bottom .dx-popover-arrow,
.dx-popover-wrapper.dx-position-top .dx-popover-arrow {
  width: 20px;
  height: 10px;
}
.dx-popover-wrapper.dx-position-left .dx-popover-arrow,
.dx-popover-wrapper.dx-position-right .dx-popover-arrow {
  width: 10px;
  height: 20px;
}
.dx-popover-arrow {
  position: absolute;
  z-index: 2000;
  overflow: hidden;
}
.dx-popover-arrow::after {
  position: absolute;
  display: block;
  overflow: hidden;
  content: ' ';
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 1px solid #d0e0ed;
}
.dx-popover-wrapper.dx-position-top .dx-popover-arrow::after {
  top: 0;
  left: 0;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}
.dx-popover-wrapper.dx-position-bottom .dx-popover-arrow::after {
  right: 0;
  bottom: 0;
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
  background: #fff;
}
.dx-popover-wrapper.dx-position-left .dx-popover-arrow::after {
  bottom: 0;
  left: 0;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}
.dx-popover-wrapper.dx-position-right .dx-popover-arrow::after {
  top: 0;
  right: 0;
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.dx-popover-wrapper .dx-overlay-content {
  overflow: visible;
}
.dx-popover-wrapper .dx-popup-content {
  overflow: hidden;
}
.dx-device-ios .dx-popover-arrow::after {
  -webkit-transform: rotate(-45deg) translateZ(0);
}
.dx-popover-wrapper .dx-popup-title {
  margin: 0;
}
.dx-popover-wrapper .dx-popup-title.dx-toolbar {
  padding-left: 15px;
}
.dx-popover-wrapper .dx-popover-arrow::after,
.dx-popover-wrapper.dx-popover-without-title .dx-popover-arrow::after {
  background: #fff;
}
.dx-popover-wrapper .dx-rtl.dx-popup-title.dx-toolbar {
  padding-right: 15px;
  padding-left: 0;
}
.dx-tooltip-wrapper .dx-overlay-content {
  border: 1px solid #d0e0ed;
  background-color: #fff;
  color: rgba(68, 81, 103, 0.85);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow::after {
  border: 1px solid #d0e0ed;
  background: #fff;
}
.dx-loadpanel-content {
  border: 1px solid #d0e0ed;
  background: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.dx-pager {
  overflow: hidden;
  width: 100%;
  line-height: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  padding-top: 9px;
  padding-bottom: 9px;
}
.dx-pager .dx-pages {
  white-space: nowrap;
  float: right;
}
.dx-pager .dx-pages .dx-page {
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 1px;
}
.dx-pager .dx-pages .dx-page:first-child {
  margin-left: 1px;
}
.dx-pager .dx-pages .dx-separator {
  display: inline-block;
}
.dx-pager .dx-pages .dx-info {
  display: inline-block;
  margin-right: 9px;
  opacity: 0.6;
}
.dx-pager .dx-pages .dx-navigate-button {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-pager .dx-pages .dx-navigate-button.dx-button-disable {
  opacity: 0.3;
  cursor: inherit;
}
.dx-pager .dx-page-sizes {
  float: left;
}
.dx-pager .dx-page-sizes .dx-page-size {
  display: inline-block;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 1px;
}
.dx-pager .dx-page-sizes .dx-page-size:first-child {
  margin-left: 1px;
}
.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  cursor: inherit;
  text-shadow: none;
  color: #fff;
  border-color: transparent;
  background-color: #7ed321;
}
.dx-pager .dx-light-pages {
  display: inline-block;
}
.dx-pager .dx-light-pages .dx-page-index {
  width: 40px;
}
.dx-pager .dx-light-pages .dx-pages-count {
  cursor: pointer;
}
.dx-pager .dx-light-pages .dx-info-text,
.dx-pager .dx-light-pages .dx-pages-count {
  padding-left: 6px;
}
.dx-pager .dx-light-pages .dx-info-text,
.dx-pager .dx-light-pages .dx-page-index,
.dx-pager .dx-light-pages .dx-pages-count {
  display: table-cell;
}
.dx-pager.dx-rtl .dx-pages,
.dx-rtl .dx-pager .dx-pages {
  float: left;
  direction: ltr;
}
.dx-pager.dx-rtl .dx-pages .dx-page,
.dx-rtl .dx-pager .dx-pages .dx-page {
  direction: ltr;
}
.dx-pager.dx-rtl .dx-pages .dx-info,
.dx-rtl .dx-pager .dx-pages .dx-info {
  direction: rtl;
  margin-left: 9px;
  margin-right: 0;
}
.dx-pager.dx-rtl .dx-page-sizes,
.dx-rtl .dx-pager .dx-page-sizes {
  float: right;
}
.dx-pager.dx-light-mode .dx-page-sizes {
  min-width: 42px;
}
.dx-pager.dx-light-mode .dx-page-index {
  min-width: 19px;
}
.dx-pager .dx-pages .dx-page {
  padding: 7px 9px 8px;
}
.dx-pager .dx-pages .dx-separator {
  padding-left: 8px;
  padding-right: 8px;
}
.dx-pager .dx-pages .dx-navigate-button {
  width: 9px;
  height: 17px;
  padding: 9px 13px;
}
.dx-pager .dx-pages .dx-prev-button {
  font: 14px/1 DXIcons;
}
.dx-pager .dx-pages .dx-prev-button::before {
  content: '';
}
.dx-pager .dx-pages .dx-next-button {
  font: 14px/1 DXIcons;
}
.dx-pager .dx-pages .dx-next-button::before {
  content: '';
}
.dx-pager .dx-pages .dx-next-button,
.dx-pager .dx-pages .dx-prev-button {
  position: relative;
  font-size: 21px;
  text-align: center;
  line-height: 21px;
}
.dx-pager .dx-pages .dx-next-button::before,
.dx-pager .dx-pages .dx-prev-button::before {
  position: absolute;
  display: block;
  width: 21px;
  top: 50%;
  margin-top: -10.5px;
  left: 50%;
  margin-left: -10.5px;
}
.dx-pager .dx-page,
.dx-pager .dx-page-size {
  border-radius: 3px;
  border: 1px solid transparent;
}
.dx-pager .dx-page-sizes .dx-page-size {
  padding: 7px 9px 8px 10px;
}
.dx-gridbase-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.dx-gridbase-container > * {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.dx-datagrid-important-margin {
  margin-right: 5px !important;
}
.dx-datagrid-table {
  background-color: transparent;
}
.dx-datagrid .dx-datagrid-content-fixed {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table {
  position: relative;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table td {
  pointer-events: auto;
}
.dx-datagrid
  .dx-datagrid-content-fixed
  .dx-datagrid-table
  .dx-row
  td.dx-pointer-events-none {
  visibility: hidden;
  background-color: transparent;
  pointer-events: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.dx-datagrid
  .dx-datagrid-content-fixed
  .dx-datagrid-table
  .dx-header-row
  td.dx-pointer-events-none {
  border-bottom-color: transparent;
}
.dx-datagrid
  .dx-datagrid-content-fixed
  .dx-datagrid-table.dx-datagrid-table-fixed
  .dx-row
  td.dx-pointer-events-none {
  width: auto;
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-top: 0;
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-pager {
  margin-top: 1px;
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-header-panel {
  border-bottom: 0;
}
.dx-datagrid.dx-datagrid-borders
  > .dx-datagrid-rowsview.dx-last-row-border
  tbody:last-child
  > .dx-data-row:nth-last-child(2)
  > td {
  border-bottom-width: 0;
}
.dx-datagrid .dx-menu-subitem ul li {
  padding-top: 0;
}
.dx-datagrid .dx-menu-subitem ul li:first-child {
  padding-top: 1px;
}
.dx-datagrid .dx-menu-subitem .dx-menu-item {
  padding: 7px 30px 7px 5px;
}
.dx-datagrid .dx-menu-subitem .dx-menu-item .dx-menu-image {
  background-position-x: left;
}
.dx-datagrid .dx-column-indicators {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.dx-datagrid .dx-column-indicators.dx-visibility-hidden {
  visibility: hidden;
}
.dx-datagrid .dx-column-indicators .dx-sort-index-icon {
  padding-right: 3px;
  margin-left: -3px;
  width: 15px;
}
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter,
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-sort,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter,
.dx-datagrid .dx-column-indicators .dx-sort.dx-sort {
  display: inline-block;
}
.dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
.dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: -7px;
}
.dx-datagrid .dx-row > td,
.dx-datagrid .dx-row > tr > td {
  padding: 7px;
}
.dx-datagrid .dx-error-row {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
.dx-datagrid .dx-error-row .dx-error-message {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.dx-datagrid .dx-error-row .dx-error-message a {
  color: inherit;
}
.dx-datagrid .dx-datagrid-rowsview .dx-data-row .dx-command-drag {
  cursor: move;
  vertical-align: middle;
  padding: 0;
}
.dx-sortable-dragging .dx-datagrid-rowsview .dx-data-row .dx-command-drag {
  cursor: pointer;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
  border-bottom: none;
  font-size: 16px;
}
.dx-datagrid-column-chooser
  .dx-overlay-content
  .dx-popup-title
  .dx-toolbar-label {
  font-size: 16px;
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-datagrid-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item {
  opacity: 0.5;
  -webkit-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}
.dx-datagrid-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item.dx-datagrid-drag-action {
  opacity: 1;
  cursor: pointer;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag
  .dx-treeview-node-container:first-child
  > .dx-treeview-node-is-leaf {
  padding: 0;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select
  .dx-datagrid-column-chooser-plain
  .dx-treeview-node {
  padding-left: 0;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select
  .dx-datagrid-column-chooser-plain
  .dx-treeview-node
  .dx-checkbox {
  left: 4px;
}
.dx-datagrid-nowrap,
.dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
  white-space: nowrap;
}
.dx-datagrid-drag-header {
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  z-index: 10000;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-datagrid-drag-header.dx-drag-command-cell {
  padding: 0;
}
.dx-datagrid-columns-separator {
  position: absolute;
  z-index: 3;
  width: 3px;
}
.dx-datagrid-columns-separator-transparent {
  border-left: 0;
  border-right: 0;
}
.dx-datagrid-tracker {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  cursor: col-resize;
}
.dx-datagrid-table-content {
  position: absolute;
  top: 0;
}
.dx-datagrid-focus-overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  visibility: hidden;
}
.dx-datagrid-action,
.dx-datagrid-drag-action {
  cursor: pointer;
}
.dx-datagrid-content {
  position: relative;
}
.dx-datagrid-content
  .dx-overlay-wrapper.dx-invalid-message.dx-datagrid-invalid-message {
  visibility: visible;
}
.dx-datagrid-content
  .dx-overlay-wrapper.dx-invalid-message.dx-datagrid-invalid-message
  .dx-overlay-content,
.dx-datagrid-text-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-datagrid-table-fixed {
  table-layout: fixed;
  width: 100%;
}
.dx-datagrid-content .dx-datagrid-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  max-width: 10px;
}
.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
  max-width: none;
}
.dx-datagrid-content
  .dx-datagrid-table.dx-datagrid-table-fixed
  .dx-column-indicators
  .dx-sort.dx-sort-none {
  display: none;
}
.dx-datagrid-content
  .dx-datagrid-table.dx-datagrid-table-fixed
  .dx-column-indicators
  .dx-sort.dx-sort-index {
  width: 26px;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-datagrid-best-fit
  .dx-column-indicators {
  float: none !important;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-datagrid-best-fit
  .dx-column-indicators
  > span {
  width: 14px;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-datagrid-best-fit
  .dx-column-indicators
  .dx-sort.dx-sort-none {
  display: inline-block;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-datagrid-best-fit
  .dx-header-row
  .dx-datagrid-text-content {
  max-width: none;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-datagrid-best-fit
  .dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-datagrid-best-fit
  .dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-datagrid-content
  .dx-datagrid-table:not(.dx-datagrid-table-fixed)
  .dx-column-indicators {
  float: none !important;
}
.dx-datagrid-content
  .dx-datagrid-table:not(.dx-datagrid-table-fixed)
  .dx-column-indicators
  > span {
  width: 14px;
}
.dx-datagrid-content
  .dx-datagrid-table:not(.dx-datagrid-table-fixed)
  .dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-datagrid-content
  .dx-datagrid-table:not(.dx-datagrid-table-fixed)
  .dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-datagrid-content
  .dx-datagrid-table:not(.dx-datagrid-table-fixed)
  .dx-master-detail-cell {
  max-width: 0;
}
.dx-datagrid-content
  .dx-datagrid-table
  [class*='column']
  + [class*='column']:last-child {
  float: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: top;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-child,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
  border-left: 0;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space,
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > tr
  > td.dx-datagrid-group-space {
  border-right: none;
  vertical-align: top;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-group-space
  + td,
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > tr
  > td.dx-datagrid-group-space
  + td {
  border-left: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-container {
  overflow: hidden;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-datagrid-invalid
  .dx-invalid-message.dx-overlay {
  position: static;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  max-width: 0;
  padding: 0;
  vertical-align: middle;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor,
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-editor-cell
  .dx-texteditor-container {
  border: 0;
  margin: 0;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  padding: 0;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit {
  text-overflow: clip;
  width: 100px;
  min-width: 100px;
  white-space: nowrap;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-command-edit.dx-command-edit-with-icons {
  width: 80px;
  min-width: 80px;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-command-edit.dx-command-edit-with-icons
  .dx-link {
  text-decoration: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit .dx-link {
  display: inline-block;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
  padding: 0;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-adaptive {
  padding: 0;
  vertical-align: middle;
}
.dx-datagrid-content
  .dx-datagrid-table
  .dx-data-row
  .dx-command-edit-with-icons {
  line-height: 1;
  white-space: nowrap;
}
.dx-datagrid-content .dx-datagrid-table .dx-filter-range-content {
  padding: 7px 7px 7px 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > .dx-last-data-cell {
  border-right: none;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > .dx-command-adaptive-hidden {
  border-left: none;
}
.dx-cell-modified,
.dx-datagrid-invalid,
.dx-highlight-outline {
  position: relative;
  padding: 7px;
}
.dx-cell-modified::after,
.dx-datagrid-invalid::after,
.dx-highlight-outline::after {
  content: '';
  position: absolute;
  border: 2px solid transparent;
  top: 0;
  left: 1px;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
.dx-cell-modified.dx-hidden,
.dx-datagrid-invalid.dx-hidden,
.dx-highlight-outline.dx-hidden {
  display: block !important;
}
.dx-cell-modified.dx-hidden::after,
.dx-datagrid-invalid.dx-hidden::after,
.dx-highlight-outline.dx-hidden::after {
  display: none;
}
.dx-editor-cell .dx-highlight-outline,
.dx-editor-cell.dx-cell-modified,
.dx-editor-cell.dx-datagrid-invalid {
  padding: 0;
}
.dx-column-lines .dx-cell-modified::after,
.dx-column-lines .dx-datagrid-invalid::after,
.dx-column-lines .dx-highlight-outline::after {
  left: 0;
}
.dx-datagrid-headers {
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-header-row > td > .dx-datagrid-text-content {
  white-space: normal;
  vertical-align: top;
}
.dx-datagrid-filter-range-overlay .dx-editor-container .dx-texteditor {
  border-width: 0;
}
.dx-datagrid-filter-range-overlay
  .dx-editor-container
  .dx-texteditor.dx-state-focused::after {
  content: ' ';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  z-index: 1;
  pointer-events: none;
}
.dx-datagrid-filter-range-overlay .dx-datagrid-filter-range-end {
  border-top: 1px solid transparent;
}
.dx-datagrid-filter-range-overlay .dx-editor-container.dx-cell-modified,
.dx-datagrid-filter-range-overlay .dx-editor-container.dx-datagrid-invalid {
  padding: 0;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-menu {
  display: none;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu {
  position: relative;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-menu {
  display: block;
}
.dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-placeholder::before,
.dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor-input {
  padding-left: 32px;
}
.dx-datagrid-filter-row .dx-cell-modified::after,
.dx-datagrid-filter-row .dx-datagrid-invalid::after {
  pointer-events: none;
}
.dx-datagrid-filter-row .dx-focused .dx-highlight-outline::after,
.dx-datagrid-filter-row .dx-focused.dx-cell-modified::after,
.dx-datagrid-filter-row .dx-focused.dx-datagrid-invalid::after {
  border-color: transparent;
}
.dx-datagrid-filter-row .dx-menu {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin-left: -2px;
  margin-top: -2px;
  height: 100%;
  overflow: visible;
}
.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal {
  height: 100%;
}
.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal .dx-menu-item-popout,
.dx-datagrid-filter-row .dx-menu .dx-menu-horizontal .dx-menu-item-text {
  display: none;
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused::after {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 100%;
  height: 102%;
  content: '';
}
.dx-datagrid-filter-row > .dx-first-cell .dx-menu,
.dx-datagrid-filter-row > td:first-child .dx-menu {
  margin-left: 0;
}
.dx-datagrid-filter-row
  .dx-menu-horizontal
  .dx-overlay-content
  ul
  .dx-menu-item {
  padding: 5px 30px 5px 5px;
}
.dx-datagrid-filter-row .dx-menu ul.dx-menu-horizontal > li > .dx-menu-item {
  padding: 8px 5px 7px;
}
.dx-datagrid-filter-row
  .dx-menu
  ul.dx-menu-horizontal
  > li
  > .dx-menu-item.dx-state-disabled:hover {
  padding: 9px 6px 8px;
}
.dx-datagrid-filter-row .dx-menu-caption {
  padding-left: 6px;
}
.dx-datagrid-filter-row .dx-menu ul .dx-menu-item .dx-menu-chouser-down {
  display: none;
}
.dx-datagrid-filter-row .dx-menu-item-highlight {
  font-weight: 400;
}
.dx-datagrid-scroll-container {
  overflow: hidden;
  width: 100%;
}
.dx-datagrid-header-panel {
  text-align: left;
}
.dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button {
  margin-left: 10px;
}
.dx-datagrid-search-panel {
  margin: 0 0 0 15px;
}
.dx-gridbase-container > .dx-datagrid-rowsview {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  height: auto;
}
.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dx-gridbase-container
  > .dx-datagrid-rowsview.dx-scrollable
  > .dx-scrollable-wrapper,
.dx-gridbase-container
  > .dx-datagrid-rowsview.dx-scrollable
  > div
  > .dx-scrollable-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: auto;
  min-width: 0;
  min-height: 0;
}
.dx-datagrid-rowsview {
  position: relative;
  overflow: hidden;
}
.dx-datagrid-rowsview.dx-empty {
  height: 100px;
}
.dx-datagrid-rowsview.dx-empty .dx-scrollable-content {
  height: 100%;
}
.dx-datagrid-rowsview.dx-fixed-columns.dx-scrollable .dx-scrollable-content {
  z-index: 2;
}
.dx-datagrid-rowsview.dx-fixed-columns .dx-scrollable-scrollbar {
  z-index: 3;
}
.dx-datagrid-rowsview .dx-datagrid-content {
  overflow-anchor: none;
}
.dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed) {
  min-height: 100%;
}
.dx-datagrid-rowsview:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-row > td,
.dx-datagrid-rowsview .dx-row > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child,
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td,
.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > tr > td {
  border-top: none;
}
.dx-datagrid-rowsview .dx-data-row > td:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-selection > td .dx-link,
.dx-datagrid-rowsview .dx-selection > tr > td .dx-link,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td .dx-link,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > tr > td .dx-link {
  color: inherit;
}
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row {
  border-top: 0;
  border-bottom: 0;
}
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row > td,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row > td {
  padding-top: 0;
  padding-bottom: 0;
}
.dx-datagrid-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td
  > .dx-select-checkbox {
  display: none;
}
.dx-datagrid-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr.dx-selection
  > td
  > .dx-select-checkbox,
.dx-datagrid-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td:hover
  > .dx-select-checkbox {
  display: inline-block;
}
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 30px;
}
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-data-row.dx-edit-row .dx-cell-modified::after {
  border-color: transparent;
}
.dx-datagrid-nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.dx-datagrid-bottom-load-panel {
  text-align: center;
  padding: 10px;
}
.dx-datagrid-hidden-column {
  white-space: nowrap;
}
.dx-datagrid .dx-row .dx-datagrid-hidden-column {
  border-right-width: 0;
  border-left-width: 0;
}
.dx-datagrid-hidden-column > * {
  display: none !important;
}
.dx-datagrid-total-footer > .dx-datagrid-content {
  padding-top: 7px;
  padding-bottom: 7px;
}
.dx-datagrid-summary-item {
  font-weight: 700;
}
.dx-datagrid-export-menu .dx-menu-item .dx-checkbox {
  margin-left: 0;
}
.dx-datagrid-export-menu .dx-menu-item .dx-checkbox .dx-checkbox-icon {
  width: 16px;
  height: 16px;
}
.dx-datagrid-export-menu .dx-menu-item .dx-checkbox .dx-checkbox-text {
  white-space: nowrap;
  word-break: normal;
}
.dx-datagrid-revert-tooltip.dx-popover-wrapper .dx-overlay-content {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dx-datagrid-revert-tooltip.dx-popover-wrapper
  .dx-overlay-content
  .dx-popup-content {
  padding: 0;
}
.dx-datagrid-revert-tooltip.dx-popover-wrapper .dx-popover-arrow {
  width: 0;
  height: 0;
}
.dx-datagrid-notouch-action {
  -ms-touch-action: none;
  touch-action: none;
  -ms-content-zooming: none;
  -ms-overflow-style: none;
}
.dx-datagrid-column-chooser-list.dx-treeview .dx-empty-message,
.dx-datagrid-column-chooser-list.dx-treeview .dx-treeview-item,
.dx-device-mobile
  .dx-datagrid-column-chooser-list.dx-treeview
  .dx-empty-message,
.dx-device-mobile
  .dx-datagrid-column-chooser-list.dx-treeview
  .dx-treeview-item {
  border: none;
}
.dx-datagrid-column-chooser-list.dx-treeview .dx-empty-message,
.dx-device-mobile
  .dx-datagrid-column-chooser-list.dx-treeview
  .dx-empty-message {
  text-align: center;
  left: 0;
  right: 0;
  bottom: 50%;
  position: absolute;
}
.dx-datagrid.dx-rtl .dx-menu-subitem .dx-menu-item,
.dx-rtl .dx-datagrid .dx-menu-subitem .dx-menu-item {
  padding: 7px 5px 7px 30px;
}
.dx-datagrid.dx-rtl .dx-menu-subitem .dx-menu-item .dx-menu-image,
.dx-rtl .dx-datagrid .dx-menu-subitem .dx-menu-item .dx-menu-image {
  background-position-x: right;
}
.dx-datagrid.dx-rtl .dx-texteditor-buttons-container,
.dx-rtl .dx-datagrid .dx-texteditor-buttons-container {
  text-align: start;
}
.dx-rtl .dx-datagrid-content .dx-datagrid-table {
  direction: rtl;
}
.dx-rtl
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-group-space {
  border-left: none;
}
.dx-rtl
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td.dx-datagrid-group-space
  + td {
  border-right: none;
}
.dx-rtl
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-editor-container
  .dx-editor-cell
  .dx-checkbox.dx-checkbox-checked
  .dx-checkbox-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-filter-range-content {
  padding: 7px 32px 7px 7px;
}
.dx-rtl .dx-datagrid-headers,
.dx-rtl .dx-datagrid-rowsview,
.dx-rtl .dx-datagrid-total-footer {
  direction: ltr;
}
.dx-rtl .dx-datagrid-headers .dx-datagrid-table,
.dx-rtl .dx-datagrid-rowsview .dx-datagrid-table,
.dx-rtl .dx-datagrid-total-footer .dx-datagrid-table {
  direction: rtl;
}
.dx-rtl
  .dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor
  .dx-placeholder::before,
.dx-rtl
  .dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor
  .dx-texteditor-input {
  padding-right: 32px;
}
.dx-rtl .dx-datagrid-filter-row .dx-menu {
  right: 0;
  left: auto;
  margin-left: 0;
  margin-right: -2px;
}
.dx-rtl .dx-datagrid-filter-row > td:first-child .dx-menu {
  margin-left: 0;
}
.dx-rtl
  .dx-datagrid-filter-row
  .dx-menu-horizontal
  .dx-overlay-content
  ul
  .dx-menu-item {
  padding: 5px 5px 5px 30px;
}
.dx-rtl .dx-datagrid-filter-row .dx-menu-caption {
  padding-right: 6px;
}
.dx-rtl .dx-datagrid-header-panel {
  text-align: right;
}
.dx-rtl .dx-datagrid-header-panel .dx-datagrid-column-chooser-button {
  margin-left: 0;
}
.dx-rtl .dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button {
  margin-left: 0;
  margin-right: 10px;
}
.dx-rtl .dx-datagrid-search-panel {
  margin: 0 15px 0 0;
}
.dx-datagrid-filter-panel,
.dx-datagrid-filter-panel-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  min-width: 0;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter:hover,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-checkbox {
  padding-right: 10px;
}
.dx-datagrid-filter-panel .dx-icon-filter {
  margin: auto 7px auto auto;
  cursor: pointer;
}
.dx-datagrid {
  position: relative;
  cursor: default;
  white-space: normal;
  line-height: normal;
}
.dx-hidden.dx-group-cell {
  display: table-cell !important;
  font-size: 0 !important;
}
.dx-datagrid-group-panel {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-datagrid-group-panel .dx-group-panel-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-datagrid-group-panel .dx-group-panel-item {
  display: inline-block;
  min-width: 30px;
  white-space: nowrap;
}
.dx-datagrid-group-panel .dx-group-panel-item .dx-sort {
  margin-left: 6px;
}
.dx-datagrid-group-panel .dx-block-separator {
  display: inline-block;
  min-width: 30px;
  white-space: nowrap;
  position: relative;
  min-width: 0;
}
.dx-datagrid-group-panel .dx-block-separator .dx-sort {
  margin-left: 6px;
}
.dx-datagrid-rowsview .dx-row.dx-group-row td {
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.dx-datagrid-rowsview .dx-row.dx-group-row:first-child td {
  border-top: none;
}
.dx-datagrid-rowsview .dx-group-row:focus {
  outline: 0;
}
.dx-datagrid-rowsview .dx-group-row.dx-row > td {
  border-left-color: transparent;
  border-right-color: transparent;
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content:focus {
  outline: 0;
}
.dx-datagrid-group-closed::before,
.dx-datagrid-group-opened::before {
  position: absolute;
  display: block;
  right: 0;
  left: 0;
}
.dx-rtl .dx-datagrid-group-closed {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-group-row.dx-row.dx-column-lines
  > td {
  border-left: none;
  border-right: none;
}
.dx-rtl .dx-datagrid-group-panel .dx-block-separator,
.dx-rtl .dx-datagrid-group-panel .dx-group-panel-item {
  margin-right: 0;
}
.dx-rtl .dx-datagrid-group-panel .dx-sort {
  margin-left: 0;
  margin-right: 6px;
}
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: 1px solid #d0e0ed;
  border-right: 1px solid #d0e0ed;
}
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-borders > .dx-datagrid-filter-panel,
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-pager {
  border-top: 1px solid #d0e0ed;
}
.dx-datagrid-borders > .dx-datagrid-filter-panel {
  border-top-width: 0;
  border-left: 1px solid #d0e0ed;
  border-right: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-sortable-dragging > div > .dx-gridbase-container > .dx-datagrid-rowsview {
  border-color: rgba(51, 122, 183, 0.5);
}
.dx-datagrid .dx-sort-up {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-sort-up::before {
  content: '';
}
.dx-datagrid .dx-sort-down {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-sort-down::before {
  content: '';
}
.dx-datagrid .dx-sort-down,
.dx-datagrid .dx-sort-up {
  font-family: inherit;
}
.dx-datagrid .dx-sort-down::before,
.dx-datagrid .dx-sort-up::before {
  font-family: DXIcons, sans-serif;
}
.dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item,
.dx-datagrid .dx-datagrid-header-panel .dx-header-filter {
  outline: 0;
}
.dx-datagrid
  .dx-datagrid-header-panel.dx-state-focused
  .dx-datagrid-group-panel
  .dx-group-panel-item:focus,
.dx-datagrid
  .dx-datagrid-header-panel.dx-state-focused
  .dx-datagrid-group-panel
  .dx-header-filter:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: -2px;
}
.dx-datagrid
  .dx-datagrid-header-panel.dx-state-focused
  .dx-datagrid-group-panel
  .dx-header-filter:focus {
  outline-offset: 2px;
}
.dx-datagrid .dx-datagrid-headers .dx-header-filter,
.dx-datagrid .dx-datagrid-headers .dx-header-row > td {
  outline: 0;
}
.dx-datagrid
  .dx-datagrid-headers.dx-state-focused
  .dx-header-row
  td
  .dx-header-filter:focus,
.dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: 2px;
}
.dx-datagrid .dx-datagrid-headers.dx-state-focused .dx-header-row td:focus {
  outline-offset: -2px;
}
.dx-datagrid .dx-header-filter {
  position: relative;
  color: #565b6f;
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-header-filter::before {
  content: '';
}
.dx-datagrid .dx-header-filter-empty {
  color: rgba(86, 91, 111, 0.5);
}
.dx-datagrid.dx-filter-menu .dx-menu-item-content .dx-icon {
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 14px;
}
.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-col-fixed {
  background-color: #fff;
}
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border-left: 2px solid #d0e0ed;
  border-right: 2px solid #d0e0ed;
}
.dx-datagrid
  .dx-datagrid-headers
  .dx-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-first-cell {
  border-left: none;
}
.dx-datagrid
  .dx-datagrid-headers
  .dx-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-last-cell {
  border-right: none;
}
.dx-datagrid
  .dx-datagrid-rowsview.dx-state-focused
  .dx-data-row
  > td.dx-command-edit
  > a.dx-link:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: 2px;
}
.dx-datagrid
  .dx-datagrid-rowsview.dx-state-focused
  .dx-data-row.dx-row-focused
  > td.dx-command-edit
  > a.dx-link:focus {
  outline-color: #fff;
  outline-offset: 0;
}
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-edit-form {
  background-color: #fff;
}
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td {
  position: relative;
}
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  > td:not(.dx-datagrid-group-space)::before {
  display: block;
  height: 100%;
  content: '';
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAACqCAYAAABbAOqQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA39pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjYWQ2ODE5MS00ZDMxLWRjNGYtOTU0NC1jNjJkMTIxMjY2M2IiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjY1RUVFQzAzRDYzMTFFODlFNThCOUJBQjU4Q0EzRDgiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjY1RUVFQkYzRDYzMTFFODlFNThCOUJBQjU4Q0EzRDgiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVlMjM1Y2U0LTc5ZWUtNGI0NC05ZjlkLTk2NTZmZGFjNjhhNCIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjk1OTQ2MjBiLTUyMTQtYTM0Yy04Nzc5LTEwMmEyMTY4MTlhOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvLbJKYAAADrSURBVHja7N3BDYBACABBsQn7L48q0BoMD5SZxAZuc74gF1V1MMfpCARBEEEQRBAEEQRBdovnuxxDq3RD/LIQRBAEQRBBEEQQBBEEQQQBAAAAAAAAABhi8gZVbgxi6kQQBBEEQQRBEEEQRBAEQRBBAAAAAAAAAAAabX2Daux2lqkTQRBEEAQRBEEEQRBBEARBBAEAAAAAAAAAaLR1g2osUyeCIIggCCIIggiCIIIgCIIIAgAAAAAAAADQ6KsbVPnXIKZOBEEQQRBEEAQRBEEEQRAEEYRXoqqcghuCIIIgiCAIIgiCCMIUtwADALYCCr92l++TAAAAAElFTkSuQmCC);
  background-repeat: no-repeat repeat;
}
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  > td[style*='text-align: right']::before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-datagrid .dx-datagrid-filter-row .dx-filter-range-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid .dx-error-row td {
  color: #fff;
  padding: 0;
}
.dx-datagrid .dx-column-lines > td:first-child {
  border-left: none;
}
.dx-datagrid .dx-column-lines > td:last-child {
  border-right: none;
}
.dx-datagrid .dx-command-drag .dx-datagrid-drag-icon {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-command-drag .dx-datagrid-drag-icon::before {
  content: '';
}
.dx-datagrid-filter-panel {
  padding: 10px;
  color: rgba(68, 81, 103, 0.85);
  border-top: 1px solid #d0e0ed;
}
.dx-datagrid-filter-panel.dx-state-focused
  .dx-datagrid-filter-panel-clear-filter:focus,
.dx-datagrid-filter-panel.dx-state-focused .dx-datagrid-filter-panel-text:focus,
.dx-datagrid-filter-panel.dx-state-focused .dx-icon-filter:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: 3px;
}
.dx-datagrid-form-buttons-container {
  float: right;
}
.dx-datagrid-form-buttons-container .dx-button {
  margin-left: 10px;
  margin-top: 10px;
}
.dx-datagrid-adaptive-more {
  cursor: pointer;
  font: 21px/21px DXIcons;
}
.dx-datagrid-adaptive-more::before {
  content: '';
}
.dx-datagrid-edit-popup .dx-error-message {
  background-color: #c76666;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
  margin-bottom: 20px;
}
.dx-rtl .dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-data-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none {
  border-left: 2px solid #d0e0ed;
  border-right: 2px solid #d0e0ed;
}
.dx-rtl
  .dx-datagrid
  .dx-datagrid-headers
  .dx-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-first-cell {
  border-right: none;
}
.dx-rtl
  .dx-datagrid
  .dx-datagrid-headers
  .dx-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-last-cell {
  border-left: none;
}
.dx-rtl .dx-datagrid .dx-datagrid-rowsview .dx-virtual-row > td::before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  > td[style*='text-align: left']::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.dx-rtl .dx-datagrid .dx-column-lines > td:first-child {
  border-right: none;
  border-left: 1px solid #d0e0ed;
}
.dx-rtl .dx-datagrid .dx-column-lines > td:last-child {
  border-left: none;
  border-right: 1px solid #d0e0ed;
}
.dx-rtl .dx-datagrid-form-buttons-container {
  float: left;
}
.dx-rtl .dx-datagrid-form-buttons-container .dx-button {
  margin-left: 0;
  margin-right: 10px;
}
.dx-datagrid-cell-updated-animation {
  -webkit-animation: 1s dx-datagrid-highlight-change;
  animation: 1s dx-datagrid-highlight-change;
}
@-webkit-keyframes dx-datagrid-highlight-change {
  50%,
  from {
    background-color: rgba(68, 81, 103, 0.08);
  }
}
@keyframes dx-datagrid-highlight-change {
  50%,
  from {
    background-color: rgba(68, 81, 103, 0.08);
  }
}
.dx-datagrid-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
  color: #afb3c1;
}
.dx-datagrid-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc,
.dx-datagrid.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc {
  font: 16px/16px DXIcons;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc::before,
.dx-datagrid.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc::before {
  content: '';
}
.dx-datagrid-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc,
.dx-datagrid.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc {
  font: 16px/16px DXIcons;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc::before,
.dx-datagrid.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-equals,
.dx-datagrid-container .dx-icon-filter-operation-equals {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-equals::before,
.dx-datagrid-container .dx-icon-filter-operation-equals::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-default,
.dx-datagrid-container .dx-icon-filter-operation-default {
  font: 12px/12px DXIcons;
  width: 12px;
  height: 12px;
  background-position: 0 0;
  background-size: 12px 12px;
  padding: 0;
  text-align: center;
}
.dx-datagrid .dx-icon-filter-operation-default::before,
.dx-datagrid-container .dx-icon-filter-operation-default::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-not-equals,
.dx-datagrid-container .dx-icon-filter-operation-not-equals {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-not-equals::before,
.dx-datagrid-container .dx-icon-filter-operation-not-equals::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-less,
.dx-datagrid-container .dx-icon-filter-operation-less {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-less::before,
.dx-datagrid-container .dx-icon-filter-operation-less::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-less-equal,
.dx-datagrid-container .dx-icon-filter-operation-less-equal {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-less-equal::before,
.dx-datagrid-container .dx-icon-filter-operation-less-equal::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-greater,
.dx-datagrid-container .dx-icon-filter-operation-greater {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-greater::before,
.dx-datagrid-container .dx-icon-filter-operation-greater::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-greater-equal,
.dx-datagrid-container .dx-icon-filter-operation-greater-equal {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-greater-equal::before,
.dx-datagrid-container .dx-icon-filter-operation-greater-equal::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-contains,
.dx-datagrid-container .dx-icon-filter-operation-contains {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-contains::before,
.dx-datagrid-container .dx-icon-filter-operation-contains::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-not-contains,
.dx-datagrid-container .dx-icon-filter-operation-not-contains {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-not-contains::before,
.dx-datagrid-container .dx-icon-filter-operation-not-contains::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-starts-with,
.dx-datagrid-container .dx-icon-filter-operation-starts-with {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-starts-with::before,
.dx-datagrid-container .dx-icon-filter-operation-starts-with::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-ends-with,
.dx-datagrid-container .dx-icon-filter-operation-ends-with {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-ends-with::before,
.dx-datagrid-container .dx-icon-filter-operation-ends-with::before {
  content: '';
}
.dx-datagrid .dx-icon-filter-operation-between,
.dx-datagrid-container .dx-icon-filter-operation-between {
  font: 14px/1 DXIcons;
}
.dx-datagrid .dx-icon-filter-operation-between::before,
.dx-datagrid-container .dx-icon-filter-operation-between::before {
  content: '';
}
.dx-datagrid {
  color: rgba(68, 81, 103, 0.85);
  background-color: #fff;
  line-height: inherit;
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: rgba(245, 245, 245, 0.5);
  border-top: 1px solid rgba(245, 245, 245, 0.5);
  border-bottom: 1px solid rgba(245, 245, 245, 0.5);
}
.dx-datagrid .dx-row-alt.dx-row:not(.dx-row-removed) {
  border-bottom-color: transparent;
}
.dx-datagrid .dx-link {
  text-decoration: underline;
  cursor: pointer;
  color: #0393f3;
}
.dx-datagrid .dx-row-lines > td {
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid .dx-column-lines > td {
  border-left: 1px solid #d0e0ed;
  border-right: 1px solid #d0e0ed;
}
.dx-datagrid .dx-error-row .dx-closebutton {
  float: right;
  margin: 9px;
  font: 14px/14px DXIcons;
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  text-align: center;
}
.dx-datagrid .dx-error-row .dx-closebutton::before {
  content: '';
}
.dx-datagrid .dx-error-row .dx-error-message {
  background-color: #c76666;
  white-space: normal;
  word-wrap: break-word;
  padding: 7px 35px 7px 7px;
}
.dx-datagrid .dx-row > td {
  padding: 7px;
}
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-command-edit-with-icons
  .dx-link {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin: 0 3px;
}
.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-command-edit-with-icons
  .dx-link:not(.dx-link-icon) {
  width: auto;
}
.dx-datagrid
  .dx-datagrid-rowsview
  .dx-virtual-row
  > td[style*='text-align: right']:before {
  background-position-y: 34px;
}
.dx-datagrid .dx-command-drag {
  width: 36px;
  min-width: 36px;
}
.dx-datagrid .dx-command-drag .dx-datagrid-drag-icon {
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 19px;
}
.dx-datagrid-edit-popup .dx-error-message {
  padding: 7px;
}
.dx-datagrid-headers .dx-texteditor-input,
.dx-datagrid-rowsview .dx-texteditor-input {
  padding: 7px;
  min-height: 33px;
}
.dx-datagrid-headers
  .dx-numberbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input,
.dx-datagrid-headers
  .dx-textbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input,
.dx-datagrid-rowsview
  .dx-numberbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input,
.dx-datagrid-rowsview
  .dx-textbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input {
  padding-right: 7px;
}
.dx-datagrid-headers .dx-lookup,
.dx-datagrid-rowsview .dx-lookup {
  height: auto;
}
.dx-datagrid-headers .dx-lookup-field,
.dx-datagrid-rowsview .dx-lookup-field {
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input {
  padding-left: 34px;
}
.dx-rtl .dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-rtl .dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-rtl .dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-rtl .dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input,
.dx-rtl.dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-rtl.dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-rtl.dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-rtl.dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input {
  padding-right: 34px;
}
.dx-datagrid-checkbox-size {
  vertical-align: middle;
  line-height: 1em;
}
.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  font-size: 12px;
}
.dx-datagrid-checkbox-size .dx-checkbox-icon {
  height: 16px;
  width: 16px;
}
.dx-datagrid-checkbox-size.dx-checkbox-indeterminate .dx-checkbox-icon {
  font-size: 6px;
}
.dx-datagrid-column-chooser-list .dx-empty-message,
.dx-device-mobile .dx-datagrid-column-chooser-list .dx-empty-message {
  color: rgba(174, 184, 201, 0.85);
  padding: 0 20px;
}
.dx-datagrid-column-chooser {
  color: rgba(68, 81, 103, 0.85);
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto;
  line-height: 1.35715;
}
.dx-datagrid-column-chooser input,
.dx-datagrid-column-chooser textarea {
  font-family: Roboto;
  line-height: 1.35715;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag
  .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag
  .dx-popup-content
  .dx-treeview-search {
  margin-bottom: 10px;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag
  .dx-popup-content
  .dx-treeview-node {
  padding-left: 20px;
}
.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select
  .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-datagrid-column-chooser .dx-overlay-content {
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
  padding-top: 7px;
  padding-bottom: 9px;
  background-color: transparent;
}
.dx-datagrid-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item {
  margin-bottom: 10px;
  background-color: #fff;
  color: #565b6f;
  font-weight: 400;
  border: 1px solid #d0e0ed;
  padding: 7px;
  -webkit-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}
.dx-datagrid-drag-header {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #565b6f;
  font-weight: 400;
  padding: 7px;
  border: 1px solid rgba(51, 122, 183, 0.5);
  background-color: #fff;
}
.dx-datagrid-columns-separator {
  background-color: rgba(51, 122, 183, 0.5);
}
.dx-datagrid-columns-separator-transparent {
  background-color: transparent;
}
.dx-datagrid-drop-highlight > td {
  background-color: #337ab7;
  color: #fff;
}
.dx-datagrid-focus-overlay {
  border: 2px solid #5c95c5;
}
.dx-datagrid-table .dx-row .dx-command-select {
  width: 70px;
  min-width: 70px;
}
.dx-datagrid-table .dx-row .dx-command-edit {
  width: 85px;
  min-width: 85px;
}
.dx-datagrid-table .dx-row .dx-command-expand {
  width: 30px;
  min-width: 30px;
}
.dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
  padding-top: 7px;
}
.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused) {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused).dx-datagrid-group-space {
  border-right-color: rgba(0, 0, 0, 0.04);
}
.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused).dx-command-select {
  overflow: inherit;
}
.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > .dx-datagrid-readonly
  .dx-texteditor
  .dx-texteditor-input {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td.dx-pointer-events-none {
  background-color: transparent;
}
.dx-datagrid-headers {
  color: #565b6f;
  font-weight: 400;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-headers .dx-datagrid-content {
  margin-bottom: -1px;
}
.dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
  border-bottom-width: 1px;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-filter-row .dx-menu .dx-overlay-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused {
  background-color: transparent;
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused::after {
  border: 2px solid #5c95c5;
}
.dx-datagrid-filter-row .dx-menu-item.dx-state-focused.dx-menu-item-expanded {
  background-color: #fff;
}
.dx-datagrid-filter-row
  .dx-menu-item.dx-state-focused.dx-menu-item-expanded::after {
  border-color: transparent;
}
.dx-datagrid-filter-row .dx-menu-item-has-submenu.dx-state-hover {
  background-color: transparent;
}
.dx-datagrid-filter-row
  .dx-menu-item-has-submenu.dx-menu-item-expanded.dx-state-hover {
  background-color: #fff;
}
.dx-datagrid-filter-row .dx-cell-modified::after,
.dx-datagrid-filter-row .dx-datagrid-invalid::after,
.dx-datagrid-filter-row .dx-highlight-outline::after {
  border-color: rgba(126, 211, 33, 0.5);
}
.dx-datagrid-filter-row .dx-menu-item-content .dx-icon {
  color: #afb3c1;
}
.dx-datagrid-filter-row td .dx-editor-container .dx-filter-range-content {
  padding: 7px 7px 7px 32px;
}
.dx-datagrid-filter-range-overlay .dx-overlay-content {
  border: 1px solid #d0e0ed;
  overflow: inherit;
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
}
.dx-datagrid-filter-range-overlay
  .dx-overlay-content
  .dx-editor-container.dx-cell-modified::after,
.dx-datagrid-filter-range-overlay
  .dx-overlay-content
  .dx-editor-container.dx-datagrid-invalid::after {
  border-color: rgba(126, 211, 33, 0.5);
  left: 0;
}
.dx-datagrid-filter-range-overlay
  .dx-overlay-content
  .dx-texteditor
  .dx-texteditor-input {
  background-color: #fff;
  padding: 7px;
}
.dx-datagrid-filter-range-overlay
  .dx-overlay-content
  .dx-texteditor.dx-state-focused::after {
  border: 2px solid #5c95c5;
}
.dx-cell-modified,
.dx-datagrid-invalid {
  padding: 7px;
}
.dx-datagrid-header-panel {
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-header-panel .dx-toolbar {
  margin-bottom: 10px;
}
.dx-datagrid-header-panel .dx-apply-button {
  background-color: #7ed321;
  border-color: #71bd1e;
  color: #fff;
}
.dx-datagrid-header-panel .dx-apply-button .dx-icon {
  color: #fff;
}
.dx-datagrid-header-panel .dx-apply-button.dx-state-hover {
  background-color: #64a71a;
  border-color: #71bd1e;
}
.dx-datagrid-header-panel .dx-apply-button.dx-state-focused {
  background-color: #569117;
  border-color: #71bd1e;
}
.dx-datagrid-header-panel .dx-apply-button.dx-state-active {
  background-color: #497b13;
  border-color: #71bd1e;
  color: #fff;
}
.dx-datagrid-addrow-button .dx-icon-edit-button-addrow {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-addrow-button .dx-icon-edit-button-addrow::before {
  content: '';
}
.dx-datagrid-cancel-button .dx-icon-edit-button-cancel {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-cancel-button .dx-icon-edit-button-cancel::before {
  content: '';
}
.dx-datagrid-save-button .dx-icon-edit-button-save {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-save-button .dx-icon-edit-button-save::before {
  content: '';
}
.dx-datagrid-export-button .dx-icon-export-to {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-export-button .dx-icon-export-to::before {
  content: '';
}
.dx-datagrid-export-button .dx-icon-export-excel-button {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-datagrid-export-button .dx-icon-export-excel-button::before {
  content: '';
}
.dx-datagrid-adaptive-more {
  width: 21px;
  height: 21px;
  background-position: 0 0;
  background-size: 21px 21px;
  padding: 0;
  font-size: 21px;
  text-align: center;
  line-height: 21px;
}
.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers + .dx-datagrid-rowsview {
  border-top: 1px solid #d0e0ed;
}
.dx-datagrid-rowsview .dx-row {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.dx-datagrid-rowsview .dx-row.dx-edit-row:first-child > td {
  border-top-width: 0;
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-rowsview .dx-row.dx-edit-row > td {
  border-top: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-rowsview .dx-row.dx-datagrid-edit-form {
  white-space: normal;
}
.dx-datagrid-rowsview .dx-master-detail-row .dx-master-detail-cell,
.dx-datagrid-rowsview .dx-master-detail-row > .dx-datagrid-group-space {
  border-top: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  .dx-master-detail-cell,
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  > .dx-datagrid-group-space {
  background-color: #fafafa;
}
.dx-datagrid-rowsview
  .dx-data-row
  .dx-validator.dx-datagrid-invalid.dx-cell-modified::after,
.dx-datagrid-rowsview
  .dx-data-row
  .dx-validator.dx-datagrid-invalid.dx-datagrid-invalid::after {
  border: 1px solid rgba(161, 0, 0, 0.4);
}
.dx-datagrid-rowsview
  .dx-data-row
  .dx-validator.dx-datagrid-invalid.dx-focused.dx-cell-modified::after,
.dx-datagrid-rowsview
  .dx-data-row
  .dx-validator.dx-datagrid-invalid.dx-focused.dx-datagrid-invalid::after {
  border: 1px solid #a10000;
}
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-datagrid-rowsview
  .dx-data-row
  .dx-cell-modified.dx-datagrid-invalid::after {
  border-color: rgba(126, 211, 33, 0.5);
}
.dx-datagrid-rowsview
  .dx-overlay-wrapper.dx-invalid-message
  .dx-overlay-content {
  padding: 9px 17px;
}
.dx-datagrid-rowsview .dx-row-removed > td {
  background-color: rgba(126, 211, 33, 0.5);
  border-top: 1px solid rgba(126, 211, 33, 0.5);
  border-bottom: 1px solid rgba(126, 211, 33, 0.5);
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-adaptive-item-text {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}
.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-datagrid-invalid {
  border: 1px solid rgba(161, 0, 0, 0.4);
}
.dx-datagrid-rowsview
  .dx-adaptive-detail-row
  .dx-datagrid-invalid.dx-adaptive-item-text {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
}
.dx-datagrid-rowsview .dx-item-modified {
  border-radius: 4px;
  border: 2px solid rgba(126, 211, 33, 0.5);
}
.dx-datagrid-rowsview .dx-item-modified.dx-adaptive-item-text {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
}
.dx-datagrid-rowsview .dx-row-focused:focus {
  outline: 0;
}
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: #5c95c5;
  color: rgba(255, 255, 255, 0.5);
}
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link
  .dx-texteditor-input,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused)
  .dx-texteditor-input,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > tr
  > td:not(.dx-focused)
  .dx-texteditor-input {
  border-radius: 0;
}
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link
  .dx-datagrid-group-closed,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link
  .dx-datagrid-group-opened,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused)
  .dx-datagrid-group-closed,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused)
  .dx-datagrid-group-opened,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > tr
  > td:not(.dx-focused)
  .dx-datagrid-group-closed,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > tr
  > td:not(.dx-focused)
  .dx-datagrid-group-opened {
  color: rgba(255, 255, 255, 0.5);
}
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit
  .dx-link:focus {
  background-color: #fff;
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
  border-bottom: 1px solid #5c95c5;
}
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > tr:first-child
  > td {
  border-top: 1px solid #5c95c5;
  border-bottom: 1px solid #5c95c5;
}
.dx-datagrid-rowsview .dx-row-focused.dx-group-row {
  background-color: #5c95c5;
  color: rgba(255, 255, 255, 0.5);
}
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-group-opened,
.dx-datagrid-rowsview .dx-row-focused.dx-group-row .dx-datagrid-summary-item {
  color: rgba(255, 255, 255, 0.5);
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  background-color: #e6e6e6;
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td.dx-datagrid-group-space,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-datagrid-group-space,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-datagrid-group-space,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-datagrid-group-space {
  border-right-color: #e6e6e6;
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td.dx-pointer-events-none,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-pointer-events-none,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-pointer-events-none,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-pointer-events-none {
  border-left-color: #d0e0ed;
  border-right-color: #d0e0ed;
}
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-focused,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-focused,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-focused,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-focused {
  background-color: #fff;
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
  > td {
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
  > tr:first-child
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
  > tr:first-child
  > td {
  border-top: 1px solid #e6e6e6;
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
  > tr:last-child
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
  > tr:last-child
  > td {
  border-bottom: 1px solid #e6e6e6;
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines
  > tr
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines
  > tr
  > td {
  border-left-color: #d0e0ed;
  border-right-color: #d0e0ed;
}
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines
  > tr
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines
  > tr
  > td {
  border-bottom-color: #d0e0ed;
}
.dx-datagrid-rowsview.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both
  .dx-scrollable-wrapper
  .dx-scrollable-container
  .dx-scrollable-content {
  padding-right: 0;
}
.dx-datagrid-rowsview td.dx-validation-pending {
  position: relative;
  padding: 0;
}
.dx-datagrid-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-datagrid-rowsview td.dx-validation-pending.dx-datagrid-invalid {
  padding: 7px 34px 7px 7px;
}
.dx-rtl .dx-datagrid-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-rtl .dx-datagrid-rowsview td.dx-validation-pending.dx-datagrid-invalid,
.dx-rtl.dx-datagrid-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-rtl.dx-datagrid-rowsview td.dx-validation-pending.dx-datagrid-invalid {
  padding-left: 34px;
  padding-right: 7px;
}
.dx-datagrid-rowsview td.dx-validation-pending .dx-pending-indicator {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  right: 4px;
  background-color: #fff;
}
.dx-rtl .dx-datagrid-rowsview td.dx-validation-pending .dx-pending-indicator,
.dx-rtl.dx-datagrid-rowsview td.dx-validation-pending .dx-pending-indicator {
  left: 4px;
  right: auto;
}
.dx-datagrid-rowsview
  td.dx-editor-cell
  .dx-texteditor.dx-validation-pending
  .dx-texteditor-input {
  padding-right: 34px;
}
.dx-datagrid-rowsview
  td.dx-editor-cell
  .dx-texteditor.dx-validation-pending.dx-rtl
  .dx-texteditor-input {
  padding-right: 7px;
  padding-bottom: 7px;
  padding-left: 34px;
}
.dx-datagrid-search-text {
  color: #fff;
  background-color: #337ab7;
}
.dx-datagrid-nodata {
  color: rgba(174, 184, 201, 0.85);
  font-size: 17px;
}
.dx-datagrid-bottom-load-panel {
  border-top: 1px solid #d0e0ed;
}
.dx-datagrid-pager {
  border-top: 3px double #d0e0ed;
}
.dx-datagrid-pager.dx-widget {
  color: rgba(68, 81, 103, 0.85);
}
.dx-datagrid-pager .dx-navigate-button,
.dx-datagrid-pager .dx-page,
.dx-datagrid-pager .dx-page-size {
  outline: 0;
}
.dx-datagrid-pager.dx-state-focused .dx-navigate-button:focus,
.dx-datagrid-pager.dx-state-focused .dx-page-size:focus,
.dx-datagrid-pager.dx-state-focused .dx-page:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: -2px;
}
.dx-datagrid-summary-item {
  color: rgba(68, 81, 103, 0.7);
}
.dx-datagrid-total-footer {
  position: relative;
  border-top: 1px solid #d0e0ed;
}
.dx-datagrid-revert-tooltip .dx-overlay-content {
  background-color: #fff;
  min-width: inherit;
}
.dx-datagrid-revert-tooltip .dx-revert-button {
  margin: 0 1px;
  background-color: #a10000;
  border-color: #800;
  color: #fff;
}
.dx-datagrid-revert-tooltip .dx-revert-button .dx-icon {
  color: #fff;
}
.dx-datagrid-revert-tooltip .dx-revert-button.dx-state-hover {
  background-color: #6e0000;
  border-color: #800;
}
.dx-datagrid-revert-tooltip .dx-revert-button.dx-state-focused {
  background-color: #500;
  border-color: #800;
}
.dx-datagrid-revert-tooltip .dx-revert-button.dx-state-active {
  background-color: #3b0000;
  border-color: #800;
  color: #fff;
}
.dx-datagrid-revert-tooltip .dx-revert-button > .dx-button-content {
  padding: 7px;
}
.dx-toolbar-menu-section .dx-datagrid-checkbox-size {
  width: 100%;
}
.dx-toolbar-menu-section .dx-datagrid-checkbox-size .dx-checkbox-container {
  padding: 14px;
}
.dx-toolbar-menu-section .dx-datagrid-checkbox-size .dx-checkbox-text {
  padding-left: 34px;
}
.dx-rtl
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused).dx-datagrid-group-space {
  border-left-color: rgba(0, 0, 0, 0.04);
  border-right-color: transparent;
}
.dx-rtl
  .dx-datagrid-filter-row
  .dx-menu
  .dx-menu-item-has-submenu.dx-menu-item-has-icon
  .dx-icon {
  margin: 0 3px;
}
.dx-rtl
  .dx-datagrid-filter-row
  td
  .dx-editor-container
  .dx-filter-range-content {
  padding: 7px 32px 7px 7px;
}
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > tr > td,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > tr > td {
  border-right-color: #d0e0ed;
}
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row
  > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row
  > tr
  > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row:hover
  > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row:hover
  > tr
  > td:not(.dx-focused).dx-datagrid-group-space {
  border-left-color: #e6e6e6;
}
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row
  > tr
  > td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row:hover
  > td.dx-pointer-events-none,
.dx-rtl
  .dx-datagrid-rowsview
  .dx-selection.dx-row:hover
  > tr
  > td.dx-pointer-events-none {
  border-left-color: #d0e0ed;
}
.dx-rtl
  .dx-datagrid-rowsview.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both
  .dx-scrollable-wrapper
  .dx-scrollable-container
  .dx-scrollable-content {
  padding-left: 0;
}
.dx-datagrid-group-panel {
  font-size: 14px;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-datagrid-group-panel .dx-group-panel-message {
  color: #565b6f;
  font-weight: 400;
  padding: 7px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.dx-datagrid-group-panel .dx-group-panel-item {
  margin-right: 10px;
  color: #565b6f;
  font-weight: 400;
  border: 1px solid #d0e0ed;
  padding: 7px;
}
.dx-datagrid-group-panel .dx-block-separator {
  margin-right: 10px;
  color: #565b6f;
  font-weight: 400;
  padding: 8px;
  background-color: #eee;
}
.dx-datagrid-group-panel .dx-sort {
  color: #afb3c1;
}
.dx-datagrid-rowsview .dx-row.dx-group-row:first-child {
  border-top: none;
}
.dx-datagrid-rowsview .dx-row.dx-group-row {
  font-weight: 700;
}
.dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  color: rgba(169, 180, 198, 0.85);
  background-color: #f7f7f7;
}
.dx-datagrid-rowsview .dx-row.dx-group-row td {
  border-top-color: #d0e0ed;
  border-bottom-color: #d0e0ed;
}
.dx-datagrid-group-opened {
  font: 18px/18px DXIcons;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
  color: rgba(189, 192, 204, 0.8);
}
.dx-datagrid-group-opened::before {
  content: '';
}
.dx-datagrid-group-closed {
  font: 18px/18px DXIcons;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
  color: rgba(189, 192, 204, 0.8);
}
.dx-datagrid-group-closed::before {
  content: '';
}
.dx-datagrid-group-closed,
.dx-datagrid-group-opened {
  cursor: pointer;
  position: relative;
  width: 100%;
}
.dx-row.dx-datagrid-group-footer.dx-column-lines {
  border-bottom: 1px solid #d0e0ed;
}
.dx-row.dx-datagrid-group-footer > td {
  background-color: #fff;
  border-top: 1px solid #d0e0ed;
  border-left-width: 0;
  border-right-width: 0;
}
.dx-rtl .dx-datagrid-group-panel .dx-block-separator,
.dx-rtl .dx-datagrid-group-panel .dx-group-panel-item {
  margin-left: 10px;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-datagrid-filter-panel .dx-icon-filter {
  outline: 0;
  color: #337ab7;
}
.dx-treelist-important-margin {
  margin-right: 5px !important;
}
.dx-treelist-table {
  background-color: transparent;
}
.dx-treelist .dx-treelist-content-fixed {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}
.dx-treelist .dx-treelist-content-fixed .dx-treelist-table {
  position: relative;
}
.dx-treelist .dx-treelist-content-fixed .dx-treelist-table td {
  pointer-events: auto;
}
.dx-treelist
  .dx-treelist-content-fixed
  .dx-treelist-table
  .dx-row
  td.dx-pointer-events-none {
  visibility: hidden;
  background-color: transparent;
  pointer-events: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.dx-treelist
  .dx-treelist-content-fixed
  .dx-treelist-table
  .dx-header-row
  td.dx-pointer-events-none {
  border-bottom-color: transparent;
}
.dx-treelist
  .dx-treelist-content-fixed
  .dx-treelist-table.dx-treelist-table-fixed
  .dx-row
  td.dx-pointer-events-none {
  width: auto;
}
.dx-treelist.dx-treelist-borders > .dx-treelist-total-footer {
  border-top: 0;
}
.dx-treelist.dx-treelist-borders > .dx-treelist-pager {
  margin-top: 1px;
}
.dx-treelist.dx-treelist-borders > .dx-treelist-header-panel {
  border-bottom: 0;
}
.dx-treelist.dx-treelist-borders
  > .dx-treelist-rowsview.dx-last-row-border
  tbody:last-child
  > .dx-data-row:nth-last-child(2)
  > td {
  border-bottom-width: 0;
}
.dx-treelist .dx-menu-subitem ul li {
  padding-top: 0;
}
.dx-treelist .dx-menu-subitem ul li:first-child {
  padding-top: 1px;
}
.dx-treelist .dx-menu-subitem .dx-menu-item {
  padding: 7px 30px 7px 5px;
}
.dx-treelist .dx-menu-subitem .dx-menu-item .dx-menu-image {
  background-position-x: left;
}
.dx-treelist .dx-link {
  text-decoration: underline;
  cursor: pointer;
}
.dx-treelist .dx-column-indicators {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.dx-treelist .dx-column-indicators.dx-visibility-hidden {
  visibility: hidden;
}
.dx-treelist .dx-column-indicators .dx-sort-index-icon {
  padding-right: 3px;
  margin-left: -3px;
  width: 15px;
}
.dx-treelist .dx-column-indicators .dx-header-filter.dx-header-filter,
.dx-treelist .dx-column-indicators .dx-header-filter.dx-sort,
.dx-treelist .dx-column-indicators .dx-sort.dx-header-filter,
.dx-treelist .dx-column-indicators .dx-sort.dx-sort {
  display: inline-block;
}
.dx-treelist .dx-column-indicators .dx-header-filter.dx-header-filter::after,
.dx-treelist .dx-column-indicators .dx-sort.dx-header-filter::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: -7px;
}
.dx-treelist .dx-row > td,
.dx-treelist .dx-row > tr > td {
  padding: 7px;
}
.dx-treelist .dx-error-row {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
.dx-treelist .dx-error-row .dx-error-message {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.dx-treelist .dx-error-row .dx-error-message a {
  color: inherit;
}
.dx-treelist .dx-treelist-rowsview .dx-data-row .dx-command-drag {
  cursor: move;
  vertical-align: middle;
  padding: 0;
}
.dx-sortable-dragging .dx-treelist-rowsview .dx-data-row .dx-command-drag {
  cursor: pointer;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.dx-sortable-without-handle .dx-data-row {
  cursor: pointer;
}
.dx-treelist-column-chooser .dx-overlay-content .dx-popup-title {
  border-bottom: none;
  font-size: 16px;
}
.dx-treelist-column-chooser
  .dx-overlay-content
  .dx-popup-title
  .dx-toolbar-label {
  font-size: 16px;
}
.dx-treelist-column-chooser .dx-overlay-content .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-treelist-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item {
  opacity: 0.5;
  -webkit-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}
.dx-treelist-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item.dx-treelist-drag-action {
  opacity: 1;
  cursor: pointer;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-drag
  .dx-treeview-node-container:first-child
  > .dx-treeview-node-is-leaf {
  padding: 0;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-select
  .dx-treelist-column-chooser-plain
  .dx-treeview-node {
  padding-left: 0;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-select
  .dx-treelist-column-chooser-plain
  .dx-treeview-node
  .dx-checkbox {
  left: 4px;
}
.dx-treelist-nowrap,
.dx-treelist-nowrap .dx-header-row > td > .dx-treelist-text-content {
  white-space: nowrap;
}
.dx-treelist-drag-header {
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  z-index: 10000;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-treelist-drag-header.dx-drag-command-cell {
  padding: 0;
}
.dx-treelist-columns-separator {
  position: absolute;
  z-index: 3;
  width: 3px;
}
.dx-treelist-columns-separator-transparent {
  border-left: 0;
  border-right: 0;
}
.dx-treelist-tracker {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  cursor: col-resize;
}
.dx-treelist-table-content {
  position: absolute;
  top: 0;
}
.dx-treelist-focus-overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  visibility: hidden;
}
.dx-treelist-action,
.dx-treelist-drag-action {
  cursor: pointer;
}
.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-modified):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused)
  .dx-link {
  color: inherit;
}
.dx-treelist-content {
  position: relative;
}
.dx-treelist-content
  .dx-overlay-wrapper.dx-invalid-message.dx-treelist-invalid-message {
  visibility: visible;
}
.dx-treelist-content
  .dx-overlay-wrapper.dx-invalid-message.dx-treelist-invalid-message
  .dx-overlay-content,
.dx-treelist-text-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-treelist-table-fixed {
  table-layout: fixed;
  width: 100%;
}
input.dx-hidden {
  display: inline-block !important;
  width: 0 !important;
}
.dx-hidden-cell {
  visibility: hidden;
}
.dx-row > td,
.dx-row > tr > td {
  border: none;
}
.dx-treelist-content .dx-treelist-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  max-width: 10px;
}
.dx-treelist-content .dx-treelist-table.dx-treelist-table-fixed {
  max-width: none;
}
.dx-treelist-content
  .dx-treelist-table.dx-treelist-table-fixed
  .dx-column-indicators
  .dx-sort.dx-sort-none {
  display: none;
}
.dx-treelist-content
  .dx-treelist-table.dx-treelist-table-fixed
  .dx-column-indicators
  .dx-sort.dx-sort-index {
  width: 26px;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-treelist-best-fit
  .dx-column-indicators {
  float: none !important;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-treelist-best-fit
  .dx-column-indicators
  > span {
  width: 14px;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-treelist-best-fit
  .dx-column-indicators
  .dx-sort.dx-sort-none {
  display: inline-block;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-treelist-best-fit
  .dx-header-row
  .dx-treelist-text-content {
  max-width: none;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-treelist-best-fit
  .dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-treelist-best-fit
  .dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-treelist-content
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-column-indicators {
  float: none !important;
}
.dx-treelist-content
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-column-indicators
  > span {
  width: 14px;
}
.dx-treelist-content
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-treelist-content
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-treelist-content
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-master-detail-cell {
  max-width: 0;
}
.dx-treelist-content
  .dx-treelist-table
  [class*='column']
  + [class*='column']:last-child {
  float: none;
}
.dx-treelist-content .dx-treelist-table .dx-row > td,
.dx-treelist-content .dx-treelist-table .dx-row > tr > td {
  vertical-align: top;
}
.dx-treelist-content .dx-treelist-table .dx-row > td:first-child,
.dx-treelist-content .dx-treelist-table .dx-row > tr > td:first-child {
  border-left: 0;
}
.dx-treelist-content .dx-treelist-table .dx-row > td.dx-treelist-group-space,
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  > tr
  > td.dx-treelist-group-space {
  border-right: none;
  vertical-align: top;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  > td.dx-treelist-group-space
  + td,
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  > tr
  > td.dx-treelist-group-space
  + td {
  border-left: none;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-editor-container {
  overflow: hidden;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-treelist-invalid
  .dx-invalid-message.dx-overlay {
  position: static;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-editor-cell {
  max-width: 0;
  padding: 0;
  vertical-align: middle;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-editor-cell .dx-texteditor,
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-editor-cell
  .dx-texteditor-container {
  border: 0;
  margin: 0;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-command-select {
  padding: 0;
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-command-edit {
  text-overflow: clip;
  width: 100px;
  min-width: 100px;
  white-space: nowrap;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-command-edit.dx-command-edit-with-icons {
  width: 80px;
  min-width: 80px;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-command-edit.dx-command-edit-with-icons
  .dx-link {
  text-decoration: none;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-command-edit .dx-link {
  display: inline-block;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-command-expand {
  padding: 0;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-treelist-content .dx-treelist-table .dx-row .dx-command-adaptive {
  padding: 0;
  vertical-align: middle;
}
.dx-treelist-content
  .dx-treelist-table
  .dx-data-row
  .dx-command-edit-with-icons {
  line-height: 1;
  white-space: nowrap;
}
.dx-treelist-content .dx-treelist-table .dx-filter-range-content {
  padding: 7px 7px 7px 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.dx-treelist-content .dx-treelist-table .dx-row > .dx-last-data-cell {
  border-right: none;
}
.dx-treelist-content .dx-treelist-table .dx-row > .dx-command-adaptive-hidden {
  border-left: none;
}
.dx-cell-modified,
.dx-highlight-outline,
.dx-treelist-invalid {
  position: relative;
  padding: 7px;
}
.dx-cell-modified::after,
.dx-highlight-outline::after,
.dx-treelist-invalid::after {
  content: '';
  position: absolute;
  border: 2px solid transparent;
  top: 0;
  left: 1px;
  bottom: 0;
  right: 0;
  pointer-events: none;
}
.dx-cell-modified.dx-hidden,
.dx-highlight-outline.dx-hidden,
.dx-treelist-invalid.dx-hidden {
  display: block !important;
}
