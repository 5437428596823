.loader-v2,
.loader-v2-fullscreen {
  height: 100%;
  width: 100%;
  align-items: center;

  .loader-v2-inner {
    justify-content: center;
    display: flex;
    width: 100%;
  }
}

.loader-v2-fullscreen {
  position: fixed;
  display: flex;
  z-index: 9999;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
