.sharedHeadingError {
  display: flex;
  align-items: center;
  margin: 4px 24px 4px 0px;
}
.contentHeadingError {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 1px solid #7ed321;
  border-radius: 4px;
  padding: 6px 12px;

  & .sharedHeadingError {
    &:first-child {
      margin-right: auto;
    }
    & .shared-tag {
      min-width: 54px;
      margin-left: 12px;
      text-align: center;
    }
  }
}
