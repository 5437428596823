@import 'variables';
.shared-select {
  &.error {
    border-color: $errorInputSelect;
  }
  background: transparent url('./../../../../assets/arrow-down.svg') no-repeat
    right 0.75rem center/8px 10px;
  color: #565b6f;
  font-size: $fontSizeInputSelect;
  font-family: $fontFamilyInputSelect;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: $heightInputSelect;
  padding-right: 30px;

  &.customSelect {
    background: transparent;
    padding-right: 0px;
    height: $heightMediumInputSelect;
    border: 1px solid #fff;
    border-radius: 0px;
    color: #fff;
    width: calc(100% - #{$heightMediumInputSelect});
    cursor: pointer;
    option {
      color: #565b6f;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:disabled ~ .custom.iconAction {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
    & ~ .custom.iconAction {
      min-width: $heightMediumInputSelect;
      height: $heightMediumInputSelect;
      background-color: #eef8ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;
    }
    &:focus {
      box-shadow: none;
      background: transparent;
    }
  }
}
.multi-select {
  --rmsc-bg: transparent !important;
  --rmsc-h: 38px !important;
  --rmsc-gray: #565b6f !important;

  background: #fff url('./../../../../assets/arrow-down.svg') no-repeat right
    0.75rem center/8px 10px;
  &:disabled,
  &.disabled {
    //opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #e9ecef;
  }
  &.error {
    & .dropdown-container {
      border-color: $errorInputSelect;
    }
  }
  .dropdown-content {
    & .panel-content {
      margin-top: 2px;
      background-color: #fff;
    }
  }
  .dropdown-heading-dropdown-arrow {
    visibility: hidden;
  }
  .dropdown-container {
    &:focus,
    &:focus-within {
      color: #495057;
      background-color: #fff;
      border-color: $borderColorInputSelect;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(19, 42, 136, 0.25);
    }
    .dropdown-heading {
      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: $borderColorInputSelect;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(19, 42, 136, 0.25);
      }
    }
  }
}
.md {
  height: $heightMediumInputSelect;
  font-size: $fontSizeMediumInputSelect;
  line-height: $lineheightMediumInputSelect;
}

.sm {
  height: $heightSmallInputSelect;
  font-size: $fontSizeSmallInputSelect;
  line-height: $lineheightSmallInputSelect;
}
