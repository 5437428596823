@import 'variables';

.shared-icon {
  display: flex;
  align-items: center;
  //display: inline;
  font-family: 'onewelf';
  margin: 0px 8px;
  color: #565b6f;
  font-size: 14px;

  & .primary {
    &::before {
      color: $primary;
    }
  }
  & .default {
    &::before {
      color: $default;
    }
  }
  & .disabled {
    cursor: initial;
    &::before {
      color: $disabled;
      opacity: 0.4;
    }
  }
  & .gray {
    &::before {
      color: $gray;
    }
  }
  & .green {
    &::before {
      color: $green;
    }
  }
  & .red {
    &::before {
      color: $red;
    }
  }
  & .blue {
    &::before {
      color: $blue;
    }
  }
  & .black {
    &::before {
      color: $black;
    }
  }
  & .white {
    &::before {
      color: $white;
    }
  }
  & .xs {
    font-size: 12px;
  }
  & .md {
    font-size: 14px;
  }
  & .lg {
    font-size: 16px;
  }
  & .x8 {
    font-size: 8px;
  }
  & .x18 {
    font-size: 18px;
  }
  & .x20 {
    font-size: 20px;
  }
  & .x24 {
    font-size: 24px;
  }
  & .x30 {
    font-size: 30px;
  }
  & .x62 {
    font-size: 62px;
  }
  .iconLabel {
    margin-left: 8px;
    font-family: 'Roboto-bold';
    font-size: 16px;
  }
}
.shared-iconHelper {
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
    font-family: 'Roboto-bold';
    font-size: 12px;
    color: #565b6f;
  }
}
.shared-bigIconBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  .icon {
    font-size: 18px;
    background: #f5f6f9;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      background: #d0e0ed;
      color: #565b6f;
      &::before {
        opacity: 0.5;
        color: #fff;
        position: relative;
      }
      &:hover {
        opacity: 1;
      }
    }
    &::before {
      color: #241d8c;
    }
    &:hover {
      opacity: 0.6;
    }
  }
  .iconLabel {
    font-family: 'Roboto-bold';
    font-size: 16px;
    color: #3e81b9;
    margin: 0px;
    &.disabled {
      color: #565b6f;
    }
  }
}
