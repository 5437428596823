$icomoon-font-family: 'onewelf' !default;
$icomoon-font-path: '../../assets/fonts-icon/';

$icon-alert-circle: '\e922';
$icon-export: '\e917';
$icon-eye: '\e924';
$icon-eye-off: '\e925';
$icon-file-text: '\e92a';
$icon-grid: '\e926';
$icon-key: '\e92c';
$icon-plus-circle: '\e92b';
$icon-save: '\e918';
$icon-shield: '\e927';
$icon-shield-off: '\e928';
$icon-trash: '\e919';
$icon-upload-f: '\e91a';
$icon-list-file: '\e929';
$icon-file-xml: '\e923';
$icon-arrow-paglast: '\e91e';
$icon-arrow-pagfirst: '\e91f';
$icon-arrow-pagright: '\e920';
$icon-arrow-pagleft: '\e921';
$icon-cal-annual: '\e91b';
$icon-cal-trim: '\e91c';
$icon-cal-month: '\e91d';
$icon-arrow-left: '\e916';
$icon-confirm: '\e915';
$icon-sort: '\e914';
$icon-delete-profile: '\e912';
$icon-delete: '\e913';
$icon-minus-circle: '\e92e';
$icon-moreV: '\e90b';
$icon-history: '\e90c';
$icon-inputDown: '\e90d';
$icon-inputUp: '\e90e';
$icon-selectorCheck: '\e90f';
$icon-selectorUncheck: '\e910';
$icon-warning: '\e911';
$icon-arrow-down: '\e900';
$icon-covip: '\e901';
$icon-down: '\e902';
$icon-edit: '\e903';
$icon-more: '\e904';
$icon-play: '\e905';
$icon-schema: '\e906';
$icon-setting: '\e907';
$icon-up: '\e908';
$icon-upload: '\e909';
$icon-user: '\e90a';
