@import 'variables';
.shared-input {
  width: $widthInput;
  height: $heightInput;
  border-radius: $borderRadiusInput;
  font-size: $fontSizeInput;
  font-family: $fontFamilyInput;
  &.error {
    border-color: $errorInput;
  }
  &.form-control:disabled {
    opacity: 0.6;
  }
}
.input-group {
  .input-group-append ~ input {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    order: 1;
  }
  .input-group-append {
    padding-right: 8px;
    border: 1px solid #ced4da;
    border-left: none;
    order: 2;
  }
}
.input-group-text {
  font-family: 'Roboto-regular';
}
.md {
  height: $heightMediumInput;
  font-size: $fontSizeMediumInput;
  line-height: $lineheightMediumInput;
}

.sm {
  height: $heightSmallInput;
  font-size: $fontSizeSmallInput;
  line-height: $lineheightSmallInput;
}
