.dx-cell-modified.dx-hidden::after,
.dx-highlight-outline.dx-hidden::after,
.dx-treelist-invalid.dx-hidden::after {
  display: none;
}
.dx-editor-cell .dx-texteditor-input {
  margin: 0;
}
.dx-editor-cell .dx-highlight-outline,
.dx-editor-cell.dx-cell-modified,
.dx-editor-cell.dx-treelist-invalid {
  padding: 0;
}
.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  display: inline-block;
  content: '​';
  vertical-align: middle;
}
.dx-column-lines .dx-cell-modified::after,
.dx-column-lines .dx-highlight-outline::after,
.dx-column-lines .dx-treelist-invalid::after {
  left: 0;
}
.dx-treelist-headers {
  position: relative;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-header-row .dx-editor-cell .dx-select-checkbox {
  display: inline-block;
}
.dx-header-row > td {
  white-space: nowrap;
  overflow: hidden;
}
.dx-header-row > td > .dx-treelist-text-content {
  white-space: normal;
  vertical-align: top;
}
.dx-header-row .dx-text-content-alignment-left,
.dx-header-row .dx-text-content-alignment-right {
  display: inline-block;
  max-width: 100%;
}
.dx-header-row .dx-header-filter-indicator,
.dx-header-row .dx-sort-indicator {
  max-width: calc(100% - 17px);
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-left,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-left {
  margin-right: 3px;
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-right,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-right {
  margin-left: 3px;
}
.dx-header-row
  .dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right,
.dx-header-row
  .dx-sort-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 34px);
}
.dx-header-row .dx-sort-indicator.dx-header-filter-indicator {
  max-width: calc(100% - 31px);
}
.dx-header-row
  .dx-sort-indicator.dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 62px);
}
.dx-header-row .dx-sort-indicator.dx-sort-index-indicator {
  max-width: calc(100% - 29px);
}
.dx-header-row
  .dx-sort-indicator.dx-sort-index-indicator.dx-header-filter-indicator {
  max-width: calc(100% - 43px);
}
.dx-header-row
  .dx-sort-indicator.dx-sort-index-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 46px);
}
.dx-header-row
  .dx-sort-indicator.dx-sort-index-indicator.dx-header-filter-indicator.dx-text-content-alignment-left.dx-text-content-alignment-right {
  max-width: calc(100% - 74px);
}
.dx-treelist-filter-range-overlay .dx-editor-container .dx-texteditor {
  border-width: 0;
}
.dx-treelist-filter-range-overlay
  .dx-editor-container
  .dx-texteditor.dx-state-focused::after {
  content: ' ';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  z-index: 1;
  pointer-events: none;
}
.dx-treelist-filter-range-overlay .dx-treelist-filter-range-end {
  border-top: 1px solid transparent;
}
.dx-treelist-filter-range-overlay .dx-editor-container.dx-cell-modified,
.dx-treelist-filter-range-overlay .dx-editor-container.dx-treelist-invalid {
  padding: 0;
}
.dx-treelist-filter-row .dx-editor-cell .dx-menu {
  display: none;
}
.dx-treelist-filter-row .dx-editor-cell .dx-editor-with-menu {
  position: relative;
}
.dx-treelist-filter-row .dx-editor-cell .dx-editor-with-menu .dx-menu {
  display: block;
}
.dx-treelist-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-placeholder::before,
.dx-treelist-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor-input {
  padding-left: 32px;
}
.dx-treelist-filter-row .dx-cell-modified::after,
.dx-treelist-filter-row .dx-treelist-invalid::after {
  pointer-events: none;
}
.dx-treelist-filter-row .dx-focused .dx-highlight-outline::after,
.dx-treelist-filter-row .dx-focused.dx-cell-modified::after,
.dx-treelist-filter-row .dx-focused.dx-treelist-invalid::after {
  border-color: transparent;
}
.dx-treelist-filter-row .dx-menu {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin-left: -2px;
  margin-top: -2px;
  height: 100%;
  overflow: visible;
}
.dx-treelist-filter-row .dx-menu .dx-menu-horizontal {
  height: 100%;
}
.dx-treelist-filter-row .dx-menu .dx-menu-horizontal .dx-menu-item-popout,
.dx-treelist-filter-row .dx-menu .dx-menu-horizontal .dx-menu-item-text {
  display: none;
}
.dx-treelist-filter-row .dx-menu-item.dx-state-focused::after {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 100%;
  height: 102%;
  content: '';
}
.dx-treelist-filter-row > .dx-first-cell .dx-menu,
.dx-treelist-filter-row > td:first-child .dx-menu {
  margin-left: 0;
}
.dx-treelist-filter-row
  .dx-menu-horizontal
  .dx-overlay-content
  ul
  .dx-menu-item {
  padding: 5px 30px 5px 5px;
}
.dx-treelist-filter-row .dx-menu ul.dx-menu-horizontal > li > .dx-menu-item {
  padding: 8px 5px 7px;
}
.dx-treelist-filter-row
  .dx-menu
  ul.dx-menu-horizontal
  > li
  > .dx-menu-item.dx-state-disabled:hover {
  padding: 9px 6px 8px;
}
.dx-treelist-filter-row .dx-menu-caption {
  padding-left: 6px;
}
.dx-treelist-filter-row .dx-menu ul .dx-menu-item .dx-menu-chouser-down {
  display: none;
}
.dx-treelist-filter-row .dx-menu-item-highlight {
  font-weight: 400;
}
.dx-treelist-scroll-container {
  overflow: hidden;
  width: 100%;
}
.dx-treelist-header-panel {
  text-align: left;
}
.dx-treelist-header-panel .dx-toolbar-menu-container .dx-button {
  margin-left: 10px;
}
.dx-state-disabled {
  cursor: pointer;
}
.dx-state-disabled .dx-menu-item {
  cursor: default;
}
.dx-treelist-search-panel {
  margin: 0 0 0 15px;
}
.dx-gridbase-container > .dx-treelist-rowsview {
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  -ms-flex: auto;
  flex: auto;
  height: auto;
}
.dx-gridbase-container > .dx-treelist-rowsview.dx-scrollable {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dx-gridbase-container
  > .dx-treelist-rowsview.dx-scrollable
  > .dx-scrollable-wrapper,
.dx-gridbase-container
  > .dx-treelist-rowsview.dx-scrollable
  > div
  > .dx-scrollable-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: auto;
  min-width: 0;
  min-height: 0;
}
.dx-treelist-rowsview {
  position: relative;
  overflow: hidden;
}
.dx-treelist-rowsview.dx-empty {
  height: 100px;
}
.dx-treelist-rowsview.dx-empty .dx-scrollable-content {
  height: 100%;
}
.dx-treelist-rowsview.dx-fixed-columns.dx-scrollable .dx-scrollable-content {
  z-index: 2;
}
.dx-treelist-rowsview.dx-fixed-columns .dx-scrollable-scrollbar {
  z-index: 3;
}
.dx-treelist-rowsview .dx-treelist-content {
  overflow-anchor: none;
}
.dx-treelist-rowsview .dx-treelist-content:not(.dx-treelist-content-fixed) {
  min-height: 100%;
}
.dx-treelist-rowsview:focus {
  outline: 0;
}
.dx-treelist-rowsview .dx-row > td,
.dx-treelist-rowsview .dx-row > tr > td {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-treelist-rowsview .dx-row.dx-row-lines:first-child,
.dx-treelist-rowsview .dx-row.dx-row-lines:first-child > td,
.dx-treelist-rowsview .dx-row.dx-row-lines:first-child > tr > td {
  border-top: none;
}
.dx-treelist-rowsview .dx-data-row > td:focus {
  outline: 0;
}
.dx-treelist-rowsview .dx-selection > td .dx-link,
.dx-treelist-rowsview .dx-selection > tr > td .dx-link,
.dx-treelist-rowsview .dx-selection.dx-row:hover > td .dx-link,
.dx-treelist-rowsview .dx-selection.dx-row:hover > tr > td .dx-link {
  color: inherit;
}
.dx-treelist-rowsview .dx-treelist-table .dx-row.dx-freespace-row,
.dx-treelist-rowsview .dx-treelist-table .dx-row.dx-virtual-row {
  border-top: 0;
  border-bottom: 0;
}
.dx-treelist-rowsview .dx-treelist-table .dx-row.dx-freespace-row > td,
.dx-treelist-rowsview .dx-treelist-table .dx-row.dx-virtual-row > td {
  padding-top: 0;
  padding-bottom: 0;
}
.dx-treelist-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td
  > .dx-select-checkbox {
  display: none;
}
.dx-treelist-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr.dx-selection
  > td
  > .dx-select-checkbox,
.dx-treelist-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td:hover
  > .dx-select-checkbox {
  display: inline-block;
}
.dx-treelist-rowsview .dx-row > .dx-master-detail-cell {
  padding: 30px;
}
.dx-treelist-rowsview .dx-row > .dx-master-detail-cell:focus {
  outline: 0;
}
.dx-treelist-rowsview .dx-data-row.dx-edit-row .dx-cell-modified::after {
  border-color: transparent;
}
.dx-treelist-nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.dx-treelist-bottom-load-panel {
  text-align: center;
  padding: 10px;
}
.dx-treelist-hidden-column {
  white-space: nowrap;
}
.dx-treelist .dx-row .dx-treelist-hidden-column {
  border-right-width: 0;
  border-left-width: 0;
}
.dx-treelist-hidden-column > * {
  display: none !important;
}
.dx-treelist-total-footer > .dx-treelist-content {
  padding-top: 7px;
  padding-bottom: 7px;
}
.dx-treelist-summary-item {
  font-weight: 700;
}
.dx-treelist-export-menu .dx-menu-item .dx-checkbox {
  margin-left: 0;
}
.dx-treelist-export-menu .dx-menu-item .dx-checkbox .dx-checkbox-icon {
  width: 16px;
  height: 16px;
}
.dx-treelist-export-menu .dx-menu-item .dx-checkbox .dx-checkbox-text {
  white-space: nowrap;
  word-break: normal;
}
.dx-command-adaptive {
  width: 36px;
  min-width: 36px;
}
.dx-treelist-revert-tooltip.dx-popover-wrapper .dx-overlay-content {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dx-treelist-revert-tooltip.dx-popover-wrapper
  .dx-overlay-content
  .dx-popup-content {
  padding: 0;
}
.dx-treelist-revert-tooltip.dx-popover-wrapper .dx-popover-arrow {
  width: 0;
  height: 0;
}
.dx-treelist-notouch-action {
  -ms-touch-action: none;
  touch-action: none;
  -ms-content-zooming: none;
  -ms-overflow-style: none;
}
.dx-device-mobile
  .dx-treelist-column-chooser-list.dx-treeview
  .dx-empty-message,
.dx-device-mobile
  .dx-treelist-column-chooser-list.dx-treeview
  .dx-treeview-item,
.dx-treelist-column-chooser-list.dx-treeview .dx-empty-message,
.dx-treelist-column-chooser-list.dx-treeview .dx-treeview-item {
  border: none;
}
.dx-device-mobile
  .dx-treelist-column-chooser-list.dx-treeview
  .dx-empty-message,
.dx-treelist-column-chooser-list.dx-treeview .dx-empty-message {
  text-align: center;
  left: 0;
  right: 0;
  bottom: 50%;
  position: absolute;
}
.dx-rtl .dx-treelist .dx-menu-subitem .dx-menu-item,
.dx-treelist.dx-rtl .dx-menu-subitem .dx-menu-item {
  padding: 7px 5px 7px 30px;
}
.dx-rtl .dx-treelist .dx-menu-subitem .dx-menu-item .dx-menu-image,
.dx-treelist.dx-rtl .dx-menu-subitem .dx-menu-item .dx-menu-image {
  background-position-x: right;
}
.dx-rtl .dx-treelist .dx-texteditor-buttons-container,
.dx-treelist.dx-rtl .dx-texteditor-buttons-container {
  text-align: start;
}
.dx-rtl .dx-treelist-content .dx-treelist-table {
  direction: rtl;
}
.dx-rtl
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  > td.dx-treelist-group-space {
  border-left: none;
}
.dx-rtl
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  > td.dx-treelist-group-space
  + td {
  border-right: none;
}
.dx-rtl
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-editor-container
  .dx-editor-cell
  .dx-checkbox.dx-checkbox-checked
  .dx-checkbox-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-filter-range-content {
  padding: 7px 32px 7px 7px;
}
.dx-rtl .dx-treelist-headers,
.dx-rtl .dx-treelist-rowsview,
.dx-rtl .dx-treelist-total-footer {
  direction: ltr;
}
.dx-rtl .dx-treelist-headers .dx-treelist-table,
.dx-rtl .dx-treelist-rowsview .dx-treelist-table,
.dx-rtl .dx-treelist-total-footer .dx-treelist-table {
  direction: rtl;
}
.dx-rtl
  .dx-treelist-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor
  .dx-placeholder::before,
.dx-rtl
  .dx-treelist-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor
  .dx-texteditor-input {
  padding-right: 32px;
}
.dx-rtl .dx-treelist-filter-row .dx-menu {
  right: 0;
  left: auto;
  margin-left: 0;
  margin-right: -2px;
}
.dx-rtl .dx-treelist-filter-row > td:first-child .dx-menu {
  margin-left: 0;
}
.dx-rtl
  .dx-treelist-filter-row
  .dx-menu-horizontal
  .dx-overlay-content
  ul
  .dx-menu-item {
  padding: 5px 5px 5px 30px;
}
.dx-rtl .dx-treelist-filter-row .dx-menu-caption {
  padding-right: 6px;
}
.dx-rtl .dx-treelist-header-panel {
  text-align: right;
}
.dx-rtl .dx-treelist-header-panel .dx-treelist-column-chooser-button {
  margin-left: 0;
}
.dx-rtl .dx-treelist-header-panel .dx-toolbar-menu-container .dx-button {
  margin-left: 0;
  margin-right: 10px;
}
.dx-rtl .dx-treelist-search-panel {
  margin: 0 15px 0 0;
}
.dx-treelist-filter-panel,
.dx-treelist-filter-panel-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  min-width: 0;
}
.dx-treelist-filter-panel .dx-treelist-filter-panel-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-treelist-filter-panel .dx-treelist-filter-panel-clear-filter:hover,
.dx-treelist-filter-panel .dx-treelist-filter-panel-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dx-treelist-filter-panel .dx-treelist-filter-panel-checkbox {
  padding-right: 10px;
}
.dx-treelist-filter-panel .dx-icon-filter {
  margin: auto 7px auto auto;
  cursor: pointer;
}
.dx-treelist-container {
  position: relative;
  cursor: default;
  white-space: normal;
  line-height: normal;
  color: rgba(68, 81, 103, 0.85);
  background-color: #fff;
}
.dx-treelist-rowsview .dx-treelist-best-fit.dx-treelist-cell-expandable,
.dx-treelist-rowsview
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-treelist-cell-expandable {
  white-space: nowrap;
}
.dx-treelist-rowsview
  .dx-treelist-best-fit.dx-treelist-cell-expandable
  .dx-treelist-text-content,
.dx-treelist-rowsview
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-treelist-cell-expandable
  .dx-treelist-text-content {
  display: inline;
  white-space: normal;
}
.dx-treelist-rowsview
  .dx-treelist-best-fit.dx-treelist-cell-expandable
  .dx-treelist-icon-container,
.dx-treelist-rowsview
  .dx-treelist-table:not(.dx-treelist-table-fixed)
  .dx-treelist-cell-expandable
  .dx-treelist-icon-container {
  float: none;
}
.dx-treelist-rowsview .dx-treelist-icon-container {
  display: inline-block;
  white-space: nowrap;
  vertical-align: top;
  line-height: 1;
}
.dx-treelist-rowsview .dx-treelist-table-fixed .dx-treelist-icon-container {
  float: left;
}
.dx-treelist-borders > .dx-treelist-headers,
.dx-treelist-borders > .dx-treelist-rowsview,
.dx-treelist-borders > .dx-treelist-total-footer {
  border-left: 1px solid #d0e0ed;
  border-right: 1px solid #d0e0ed;
}
.dx-treelist-borders > .dx-treelist-rowsview,
.dx-treelist-borders > .dx-treelist-total-footer {
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-borders > .dx-treelist-filter-panel,
.dx-treelist-borders > .dx-treelist-headers,
.dx-treelist-borders > .dx-treelist-pager {
  border-top: 1px solid #d0e0ed;
}
.dx-treelist-borders > .dx-treelist-filter-panel {
  border-top-width: 0;
  border-left: 1px solid #d0e0ed;
  border-right: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-sortable-dragging > div > .dx-gridbase-container > .dx-treelist-rowsview {
  border-color: rgba(51, 122, 183, 0.5);
}
.dx-treelist .dx-column-lines > td:first-child {
  border-left: none;
}
.dx-treelist .dx-column-lines > td:last-child {
  border-right: none;
}
.dx-treelist .dx-command-drag .dx-treelist-drag-icon {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-command-drag .dx-treelist-drag-icon::before {
  content: '';
}
.dx-treelist-container .dx-sort-up {
  font: 14px/1 DXIcons;
}
.dx-treelist-container .dx-sort-up::before {
  content: '';
}
.dx-treelist-container .dx-sort-down {
  font: 14px/1 DXIcons;
}
.dx-treelist-container .dx-sort-down::before {
  content: '';
}
.dx-treelist-container .dx-sort-down,
.dx-treelist-container .dx-sort-up {
  font-family: inherit;
}
.dx-treelist-container .dx-sort-down::before,
.dx-treelist-container .dx-sort-up::before {
  font-family: DXIcons, sans-serif;
}
.dx-treelist-container .dx-treelist-headers .dx-header-filter,
.dx-treelist-container .dx-treelist-headers .dx-header-row > td {
  outline: 0;
}
.dx-treelist-container
  .dx-treelist-headers.dx-state-focused
  .dx-header-row
  td
  .dx-header-filter:focus,
.dx-treelist-container
  .dx-treelist-headers.dx-state-focused
  .dx-header-row
  td:focus {
  outline: #5c95c5 solid 2px;
}
.dx-treelist-container
  .dx-treelist-headers.dx-state-focused
  .dx-header-row
  td:focus {
  outline-offset: -2px;
}
.dx-treelist-container
  .dx-treelist-headers.dx-state-focused
  .dx-header-row
  td
  .dx-header-filter:focus {
  outline-offset: 2px;
}
.dx-treelist-container .dx-header-filter {
  position: relative;
  color: #565b6f;
  font: 14px/1 DXIcons;
}
.dx-treelist-container .dx-header-filter::before {
  content: '';
}
.dx-treelist-container .dx-header-filter-empty {
  color: rgba(86, 91, 111, 0.5);
}
.dx-treelist-container.dx-filter-menu .dx-menu-item-content .dx-icon {
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 14px;
}
.dx-treelist-container
  .dx-treelist-content-fixed
  .dx-treelist-table
  .dx-col-fixed {
  background-color: #fff;
}
.dx-treelist-container
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none,
.dx-treelist-container
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-headers .dx-row td.dx-pointer-events-none,
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-data-row
  td.dx-pointer-events-none,
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none {
  border-left: 2px solid #d0e0ed;
  border-right: 2px solid #d0e0ed;
}
.dx-treelist-container
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-treelist-container
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-treelist-container
  .dx-treelist-headers
  .dx-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-first-cell {
  border-left: none;
}
.dx-treelist-container
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-treelist-container
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-treelist-container
  .dx-treelist-headers
  .dx-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-last-cell {
  border-right: none;
}
.dx-treelist-container
  .dx-treelist-rowsview.dx-state-focused
  .dx-data-row
  > td.dx-command-edit
  > a.dx-link:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: 2px;
}
.dx-treelist-container
  .dx-treelist-rowsview.dx-state-focused
  .dx-data-row.dx-row-focused
  > td.dx-command-edit
  > a.dx-link:focus {
  outline-color: #fff;
  outline-offset: 0;
}
.dx-treelist-container .dx-treelist-rowsview .dx-treelist-edit-form {
  background-color: #fff;
}
.dx-treelist-container .dx-treelist-rowsview .dx-virtual-row > td {
  position: relative;
}
.dx-treelist-container .dx-treelist-rowsview .dx-virtual-row > td::before {
  display: block;
  height: 100%;
  content: '';
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAACqCAYAAABbAOqQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA39pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjYWQ2ODE5MS00ZDMxLWRjNGYtOTU0NC1jNjJkMTIxMjY2M2IiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjY1RUVFQzAzRDYzMTFFODlFNThCOUJBQjU4Q0EzRDgiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjY1RUVFQkYzRDYzMTFFODlFNThCOUJBQjU4Q0EzRDgiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVlMjM1Y2U0LTc5ZWUtNGI0NC05ZjlkLTk2NTZmZGFjNjhhNCIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjk1OTQ2MjBiLTUyMTQtYTM0Yy04Nzc5LTEwMmEyMTY4MTlhOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvLbJKYAAADrSURBVHja7N3BDYBACABBsQn7L48q0BoMD5SZxAZuc74gF1V1MMfpCARBEEEQRBAEEQRBdovnuxxDq3RD/LIQRBAEQRBBEEQQBBEEQQQBAAAAAAAAABhi8gZVbgxi6kQQBBEEQQRBEEEQRBAEQRBBAAAAAAAAAAAabX2Daux2lqkTQRBEEAQRBEEEQRBBEARBBAEAAAAAAAAAaLR1g2osUyeCIIggCCIIggiCIIIgCIIIAgAAAAAAAADQ6KsbVPnXIKZOBEEQQRBEEAQRBEEEQRAEEYRXoqqcghuCIIIgiCAIIgiCCMIUtwADALYCCr92l++TAAAAAElFTkSuQmCC);
  background-repeat: no-repeat repeat;
}
.dx-treelist-container
  .dx-treelist-rowsview
  .dx-virtual-row
  > td[style*='text-align: right']::before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-treelist-container .dx-treelist-filter-row .dx-filter-range-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-container .dx-error-row td {
  color: #fff;
  padding: 0;
}
.dx-treelist-container .dx-error-row .dx-error-message {
  background-color: #c76666;
  white-space: normal;
  word-wrap: break-word;
}
.dx-treelist-filter-panel {
  padding: 10px;
  color: rgba(68, 81, 103, 0.85);
  border-top: 1px solid #d0e0ed;
}
.dx-treelist-filter-panel.dx-state-focused .dx-icon-filter:focus,
.dx-treelist-filter-panel.dx-state-focused
  .dx-treelist-filter-panel-clear-filter:focus,
.dx-treelist-filter-panel.dx-state-focused
  .dx-treelist-filter-panel-text:focus {
  outline: #5c95c5 solid 2px;
}
.dx-treelist-form-buttons-container {
  float: right;
}
.dx-treelist-form-buttons-container .dx-button {
  margin-left: 10px;
  margin-top: 10px;
}
.dx-treelist-export-menu .dx-menu-item .dx-icon-exportxlsx {
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  font-size: 16px;
  text-align: center;
  line-height: 16px;
}
.dx-treelist-adaptive-more {
  cursor: pointer;
  font: 21px/21px DXIcons;
}
.dx-treelist-adaptive-more::before {
  content: '';
}
.dx-treelist-edit-popup .dx-error-message {
  background-color: #c76666;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
  margin-bottom: 20px;
}
.dx-rtl.dx-treelist .dx-column-lines > td:first-child {
  border-right: none;
  border-left: 1px solid #d0e0ed;
}
.dx-rtl.dx-treelist .dx-column-lines > td:last-child {
  border-left: none;
  border-right: 1px solid #d0e0ed;
}
.dx-rtl
  .dx-treelist-container
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-container
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-headers
  .dx-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-data-row
  td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none {
  border-left: 2px solid #d0e0ed;
  border-right: 2px solid #d0e0ed;
}
.dx-rtl
  .dx-treelist-container
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-treelist-container
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-headers
  .dx-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-first-cell,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-first-cell {
  border-right: none;
}
.dx-rtl
  .dx-treelist-container
  .dx-datagrid-rowsview
  .dx-header-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-treelist-container
  .dx-datagrid-rowsview
  .dx-virtual-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-headers
  .dx-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-data-row
  td.dx-pointer-events-none.dx-last-cell,
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-freespace-row
  td.dx-pointer-events-none.dx-last-cell {
  border-left: none;
}
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-virtual-row
  > td::before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl
  .dx-treelist-container
  .dx-treelist-rowsview
  .dx-virtual-row
  > td[style*='text-align: left']::before {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.dx-rtl .dx-treelist-form-buttons-container {
  float: left;
}
.dx-rtl .dx-treelist-form-buttons-container .dx-button {
  margin-left: 0;
  margin-right: 10px;
}
.dx-treelist-cell-updated-animation {
  -webkit-animation: 1s dx-treelist-highlight-change;
  animation: 1s dx-treelist-highlight-change;
}
@-webkit-keyframes dx-treelist-highlight-change {
  50%,
  from {
    background-color: rgba(68, 81, 103, 0.08);
  }
}
@keyframes dx-treelist-highlight-change {
  50%,
  from {
    background-color: rgba(68, 81, 103, 0.08);
  }
}
.dx-treelist-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
.dx-treelist.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
  color: #afb3c1;
}
.dx-treelist-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc,
.dx-treelist.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc {
  font: 16px/16px DXIcons;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  text-align: center;
}
.dx-treelist-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc::before,
.dx-treelist.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-asc::before {
  content: '';
}
.dx-treelist-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc,
.dx-treelist.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc {
  font: 16px/16px DXIcons;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  text-align: center;
}
.dx-treelist-container.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc::before,
.dx-treelist.dx-context-menu
  .dx-menu-items-container
  .dx-icon-context-menu-sort-desc::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-equals,
.dx-treelist-container .dx-icon-filter-operation-equals {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-equals::before,
.dx-treelist-container .dx-icon-filter-operation-equals::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-default,
.dx-treelist-container .dx-icon-filter-operation-default {
  font: 12px/12px DXIcons;
  width: 12px;
  height: 12px;
  background-position: 0 0;
  background-size: 12px 12px;
  padding: 0;
  text-align: center;
}
.dx-treelist .dx-icon-filter-operation-default::before,
.dx-treelist-container .dx-icon-filter-operation-default::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-not-equals,
.dx-treelist-container .dx-icon-filter-operation-not-equals {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-not-equals::before,
.dx-treelist-container .dx-icon-filter-operation-not-equals::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-less,
.dx-treelist-container .dx-icon-filter-operation-less {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-less::before,
.dx-treelist-container .dx-icon-filter-operation-less::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-less-equal,
.dx-treelist-container .dx-icon-filter-operation-less-equal {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-less-equal::before,
.dx-treelist-container .dx-icon-filter-operation-less-equal::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-greater,
.dx-treelist-container .dx-icon-filter-operation-greater {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-greater::before,
.dx-treelist-container .dx-icon-filter-operation-greater::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-greater-equal,
.dx-treelist-container .dx-icon-filter-operation-greater-equal {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-greater-equal::before,
.dx-treelist-container .dx-icon-filter-operation-greater-equal::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-contains,
.dx-treelist-container .dx-icon-filter-operation-contains {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-contains::before,
.dx-treelist-container .dx-icon-filter-operation-contains::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-not-contains,
.dx-treelist-container .dx-icon-filter-operation-not-contains {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-not-contains::before,
.dx-treelist-container .dx-icon-filter-operation-not-contains::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-starts-with,
.dx-treelist-container .dx-icon-filter-operation-starts-with {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-starts-with::before,
.dx-treelist-container .dx-icon-filter-operation-starts-with::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-ends-with,
.dx-treelist-container .dx-icon-filter-operation-ends-with {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-ends-with::before,
.dx-treelist-container .dx-icon-filter-operation-ends-with::before {
  content: '';
}
.dx-treelist .dx-icon-filter-operation-between,
.dx-treelist-container .dx-icon-filter-operation-between {
  font: 14px/1 DXIcons;
}
.dx-treelist .dx-icon-filter-operation-between::before,
.dx-treelist-container .dx-icon-filter-operation-between::before {
  content: '';
}
.dx-treelist {
  line-height: inherit;
}
.dx-treelist .dx-row-alt > td,
.dx-treelist .dx-row-alt > tr > td {
  background-color: rgba(245, 245, 245, 0.5);
  border-top: 1px solid rgba(245, 245, 245, 0.5);
  border-bottom: 1px solid rgba(245, 245, 245, 0.5);
}
.dx-treelist .dx-row-alt.dx-row:not(.dx-row-removed) {
  border-bottom-color: transparent;
}
.dx-treelist .dx-link {
  color: #0393f3;
}
.dx-treelist .dx-row-lines > td {
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist .dx-column-lines > td {
  border-left: 1px solid #d0e0ed;
  border-right: 1px solid #d0e0ed;
}
.dx-treelist .dx-error-row .dx-closebutton {
  float: right;
  margin: 9px;
  font: 14px/14px DXIcons;
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  text-align: center;
}
.dx-treelist .dx-error-row .dx-closebutton::before {
  content: '';
}
.dx-treelist .dx-error-row .dx-error-message {
  padding: 7px 35px 7px 7px;
}
.dx-treelist .dx-row > td {
  padding: 7px;
}
.dx-treelist
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-command-edit-with-icons
  .dx-link {
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  margin: 0 3px;
}
.dx-treelist
  .dx-treelist-content
  .dx-treelist-table
  .dx-row
  .dx-command-edit-with-icons
  .dx-link:not(.dx-link-icon) {
  width: auto;
}
.dx-treelist
  .dx-treelist-rowsview
  .dx-virtual-row
  > td[style*='text-align: right']:before {
  background-position-y: 34px;
}
.dx-treelist .dx-command-drag {
  width: 36px;
  min-width: 36px;
}
.dx-treelist .dx-command-drag .dx-treelist-drag-icon {
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 19px;
}
.dx-treelist-edit-popup .dx-error-message {
  padding: 7px;
}
.dx-treelist-headers .dx-texteditor-input,
.dx-treelist-rowsview .dx-texteditor-input {
  padding: 7px;
  min-height: 33px;
}
.dx-treelist-headers
  .dx-numberbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input,
.dx-treelist-headers
  .dx-textbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input,
.dx-treelist-rowsview
  .dx-numberbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input,
.dx-treelist-rowsview
  .dx-textbox.dx-show-clear-button.dx-texteditor-empty:not(.dx-invalid)
  .dx-texteditor-input {
  padding-right: 7px;
}
.dx-treelist-headers .dx-lookup,
.dx-treelist-rowsview .dx-lookup {
  height: auto;
}
.dx-treelist-headers .dx-lookup-field,
.dx-treelist-rowsview .dx-lookup-field {
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.dx-treelist-headers .dx-searchbox .dx-placeholder::before,
.dx-treelist-headers .dx-searchbox .dx-texteditor-input,
.dx-treelist-rowsview .dx-searchbox .dx-placeholder::before,
.dx-treelist-rowsview .dx-searchbox .dx-texteditor-input {
  padding-left: 34px;
}
.dx-rtl .dx-treelist-headers .dx-searchbox .dx-placeholder::before,
.dx-rtl .dx-treelist-headers .dx-searchbox .dx-texteditor-input,
.dx-rtl .dx-treelist-rowsview .dx-searchbox .dx-placeholder::before,
.dx-rtl .dx-treelist-rowsview .dx-searchbox .dx-texteditor-input,
.dx-rtl.dx-treelist-headers .dx-searchbox .dx-placeholder::before,
.dx-rtl.dx-treelist-headers .dx-searchbox .dx-texteditor-input,
.dx-rtl.dx-treelist-rowsview .dx-searchbox .dx-placeholder::before,
.dx-rtl.dx-treelist-rowsview .dx-searchbox .dx-texteditor-input {
  padding-right: 34px;
}
.dx-editor-cell .dx-numberbox-spin-button {
  background-color: transparent;
}
.dx-editor-cell .dx-icon-clear {
  left: 0;
}
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: #fff;
}
.dx-editor-cell .dx-dropdowneditor {
  background-color: #fff;
}
.dx-editor-cell.dx-focused .dx-dropdowneditor-icon {
  border-radius: 0;
}
.dx-editor-cell.dx-editor-inline-block:not(.dx-command-select)::before {
  padding-top: 7px;
  padding-bottom: 7px;
}
.dx-treelist-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
  font-size: 12px;
}
.dx-treelist-checkbox-size .dx-checkbox-icon {
  height: 16px;
  width: 16px;
}
.dx-treelist-checkbox-size.dx-checkbox-indeterminate .dx-checkbox-icon {
  font-size: 6px;
}
.dx-device-mobile .dx-treelist-column-chooser-list .dx-empty-message,
.dx-treelist-column-chooser-list .dx-empty-message {
  color: rgba(174, 184, 201, 0.85);
  padding: 0 20px;
}
.dx-treelist-column-chooser {
  color: rgba(68, 81, 103, 0.85);
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto;
  line-height: 1.35715;
}
.dx-treelist-column-chooser input,
.dx-treelist-column-chooser textarea {
  font-family: Roboto;
  line-height: 1.35715;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-drag
  .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-drag
  .dx-popup-content
  .dx-treeview-search {
  margin-bottom: 10px;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-drag
  .dx-popup-content
  .dx-treeview-node {
  padding-left: 20px;
}
.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-select
  .dx-popup-content {
  padding: 0 20px 20px;
}
.dx-treelist-column-chooser .dx-overlay-content {
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.dx-treelist-column-chooser .dx-overlay-content .dx-popup-title {
  padding-top: 7px;
  padding-bottom: 9px;
  background-color: transparent;
}
.dx-treelist-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item {
  margin-bottom: 10px;
  background-color: #fff;
  color: #565b6f;
  font-weight: 400;
  border: 1px solid #d0e0ed;
  padding: 7px;
  -webkit-box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}
.dx-treelist-drag-header {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #565b6f;
  font-weight: 400;
  padding: 7px;
  border: 1px solid rgba(51, 122, 183, 0.5);
  background-color: #fff;
}
.dx-treelist-columns-separator {
  background-color: rgba(51, 122, 183, 0.5);
}
.dx-treelist-columns-separator-transparent {
  background-color: transparent;
}
.dx-treelist-drop-highlight > td {
  background-color: #337ab7;
  color: #fff;
}
.dx-treelist-focus-overlay {
  border: 2px solid #5c95c5;
}
.dx-treelist-table .dx-row .dx-command-select {
  width: 70px;
  min-width: 70px;
}
.dx-treelist-table .dx-row .dx-command-edit {
  width: 85px;
  min-width: 85px;
}
.dx-treelist-table .dx-row .dx-command-expand {
  width: 30px;
  min-width: 30px;
}
.dx-treelist-table .dx-row .dx-command-expand.dx-treelist-group-space {
  padding-top: 7px;
}
.dx-treelist-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused) {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused).dx-treelist-group-space {
  border-right-color: rgba(0, 0, 0, 0.04);
}
.dx-treelist-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused).dx-command-select {
  overflow: inherit;
}
.dx-treelist-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > .dx-treelist-readonly
  .dx-texteditor
  .dx-texteditor-input {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td.dx-pointer-events-none {
  background-color: transparent;
}
.dx-treelist-headers {
  color: #565b6f;
  font-weight: 400;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-headers .dx-treelist-content {
  margin-bottom: -1px;
}
.dx-treelist-borders .dx-treelist-headers .dx-treelist-table {
  border-bottom-width: 1px;
}
.dx-treelist-headers .dx-treelist-table .dx-row > td {
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-filter-row .dx-menu .dx-overlay-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-filter-row .dx-menu-item.dx-state-focused {
  background-color: transparent;
}
.dx-treelist-filter-row .dx-menu-item.dx-state-focused::after {
  border: 2px solid #5c95c5;
}
.dx-treelist-filter-row .dx-menu-item.dx-state-focused.dx-menu-item-expanded {
  background-color: #fff;
}
.dx-treelist-filter-row
  .dx-menu-item.dx-state-focused.dx-menu-item-expanded::after {
  border-color: transparent;
}
.dx-treelist-filter-row .dx-menu-item-has-submenu.dx-state-hover {
  background-color: transparent;
}
.dx-treelist-filter-row
  .dx-menu-item-has-submenu.dx-menu-item-expanded.dx-state-hover {
  background-color: #fff;
}
.dx-treelist-filter-row .dx-cell-modified::after,
.dx-treelist-filter-row .dx-highlight-outline::after,
.dx-treelist-filter-row .dx-treelist-invalid::after {
  border-color: rgba(126, 211, 33, 0.5);
}
.dx-treelist-filter-row .dx-menu-item-content .dx-icon {
  color: #afb3c1;
}
.dx-treelist-filter-row td .dx-editor-container .dx-filter-range-content {
  padding: 7px 7px 7px 32px;
}
.dx-treelist-filter-range-overlay .dx-overlay-content {
  border: 1px solid #d0e0ed;
  overflow: inherit;
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
}
.dx-treelist-filter-range-overlay
  .dx-overlay-content
  .dx-editor-container.dx-cell-modified::after,
.dx-treelist-filter-range-overlay
  .dx-overlay-content
  .dx-editor-container.dx-treelist-invalid::after {
  border-color: rgba(126, 211, 33, 0.5);
  left: 0;
}
.dx-treelist-filter-range-overlay
  .dx-overlay-content
  .dx-texteditor
  .dx-texteditor-input {
  background-color: #fff;
  padding: 7px;
}
.dx-treelist-filter-range-overlay
  .dx-overlay-content
  .dx-texteditor.dx-state-focused::after {
  border: 2px solid #5c95c5;
}
.dx-filter-menu.dx-menu {
  background-color: transparent;
  height: 100%;
}
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 6px 5px 7px;
}
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
  margin: 0 3px;
}
.dx-editor-with-menu
  .dx-filter-menu
  .dx-menu-item-content
  .dx-icon.dx-icon-filter-operation-default,
.dx-filter-menu
  .dx-menu-item-content
  .dx-icon.dx-icon-filter-operation-default {
  margin-top: 2px;
}
.dx-cell-modified,
.dx-treelist-invalid {
  padding: 7px;
}
.dx-treelist-header-panel {
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-header-panel .dx-toolbar {
  margin-bottom: 10px;
}
.dx-treelist-header-panel .dx-apply-button {
  background-color: #7ed321;
  border-color: #71bd1e;
  color: #fff;
}
.dx-treelist-header-panel .dx-apply-button .dx-icon {
  color: #fff;
}
.dx-treelist-header-panel .dx-apply-button.dx-state-hover {
  background-color: #64a71a;
  border-color: #71bd1e;
}
.dx-treelist-header-panel .dx-apply-button.dx-state-focused {
  background-color: #569117;
  border-color: #71bd1e;
}
.dx-treelist-header-panel .dx-apply-button.dx-state-active {
  background-color: #497b13;
  border-color: #71bd1e;
  color: #fff;
}
.dx-icon-column-chooser {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-icon-column-chooser::before {
  content: '';
}
.dx-treelist-addrow-button .dx-icon-edit-button-addrow {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-treelist-addrow-button .dx-icon-edit-button-addrow::before {
  content: '';
}
.dx-treelist-cancel-button .dx-icon-edit-button-cancel {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-treelist-cancel-button .dx-icon-edit-button-cancel::before {
  content: '';
}
.dx-treelist-save-button .dx-icon-edit-button-save {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-treelist-save-button .dx-icon-edit-button-save::before {
  content: '';
}
.dx-apply-button .dx-icon-apply-filter {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-apply-button .dx-icon-apply-filter::before {
  content: '';
}
.dx-treelist-export-button .dx-icon-export-to {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-treelist-export-button .dx-icon-export-to::before {
  content: '';
}
.dx-treelist-export-button .dx-icon-export-excel-button {
  font: 18px/18px DXIcons;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  text-align: center;
}
.dx-treelist-export-button .dx-icon-export-excel-button::before {
  content: '';
}
.dx-treelist-adaptive-more {
  width: 21px;
  height: 21px;
  background-position: 0 0;
  background-size: 21px 21px;
  padding: 0;
  font-size: 21px;
  text-align: center;
  line-height: 21px;
}
.dx-treelist-borders .dx-treelist-rowsview,
.dx-treelist-headers + .dx-treelist-rowsview {
  border-top: 1px solid #d0e0ed;
}
.dx-treelist-rowsview .dx-row {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.dx-treelist-rowsview .dx-row.dx-edit-row:first-child > td {
  border-top-width: 0;
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-rowsview .dx-row.dx-edit-row > td {
  border-top: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-rowsview .dx-row.dx-treelist-edit-form {
  white-space: normal;
}
.dx-treelist-rowsview .dx-master-detail-row .dx-master-detail-cell,
.dx-treelist-rowsview .dx-master-detail-row > .dx-treelist-group-space {
  border-top: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-treelist-rowsview
  .dx-master-detail-row:not(.dx-treelist-edit-form)
  .dx-master-detail-cell,
.dx-treelist-rowsview
  .dx-master-detail-row:not(.dx-treelist-edit-form)
  > .dx-treelist-group-space {
  background-color: #fafafa;
}
.dx-treelist-rowsview
  .dx-data-row
  .dx-validator.dx-treelist-invalid.dx-cell-modified::after,
.dx-treelist-rowsview
  .dx-data-row
  .dx-validator.dx-treelist-invalid.dx-treelist-invalid::after {
  border: 1px solid rgba(161, 0, 0, 0.4);
}
.dx-treelist-rowsview
  .dx-data-row
  .dx-validator.dx-treelist-invalid.dx-focused.dx-cell-modified::after,
.dx-treelist-rowsview
  .dx-data-row
  .dx-validator.dx-treelist-invalid.dx-focused.dx-treelist-invalid::after {
  border: 1px solid #a10000;
}
.dx-treelist-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-treelist-rowsview
  .dx-data-row
  .dx-cell-modified.dx-treelist-invalid::after {
  border-color: rgba(126, 211, 33, 0.5);
}
.dx-treelist-rowsview
  .dx-overlay-wrapper.dx-invalid-message
  .dx-overlay-content {
  padding: 9px 17px;
}
.dx-treelist-rowsview .dx-row-removed > td {
  background-color: rgba(126, 211, 33, 0.5);
  border-top: 1px solid rgba(126, 211, 33, 0.5);
  border-bottom: 1px solid rgba(126, 211, 33, 0.5);
}
.dx-treelist-rowsview .dx-adaptive-detail-row .dx-adaptive-item-text {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}
.dx-treelist-rowsview .dx-adaptive-detail-row .dx-treelist-invalid {
  border: 1px solid rgba(161, 0, 0, 0.4);
}
.dx-treelist-rowsview
  .dx-adaptive-detail-row
  .dx-treelist-invalid.dx-adaptive-item-text {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
}
.dx-treelist-rowsview .dx-item-modified {
  border-radius: 4px;
  border: 2px solid rgba(126, 211, 33, 0.5);
}
.dx-treelist-rowsview .dx-item-modified.dx-adaptive-item-text {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
}
.dx-treelist-rowsview .dx-row-focused:focus {
  outline: 0;
}
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link,
.dx-treelist-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-treelist-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: #5c95c5;
  color: rgba(255, 255, 255, 0.5);
}
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link
  .dx-texteditor-input,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused)
  .dx-texteditor-input,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > tr
  > td:not(.dx-focused)
  .dx-texteditor-input {
  border-radius: 0;
}
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link
  .dx-treelist-group-closed,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit:not(.dx-focused)
  .dx-link
  .dx-treelist-group-opened,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused)
  .dx-treelist-group-closed,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused)
  .dx-treelist-group-opened,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > tr
  > td:not(.dx-focused)
  .dx-treelist-group-closed,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  > tr
  > td:not(.dx-focused)
  .dx-treelist-group-opened {
  color: rgba(255, 255, 255, 0.5);
}
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row
  .dx-command-edit
  .dx-link:focus {
  background-color: #fff;
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-rowsview .dx-row-focused.dx-data-row > td,
.dx-treelist-rowsview .dx-row-focused.dx-data-row > tr:last-child > td {
  border-bottom: 1px solid #5c95c5;
}
.dx-treelist-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
.dx-treelist-rowsview
  .dx-row-focused.dx-data-row:not(.dx-row-lines)
  > tr:first-child
  > td {
  border-top: 1px solid #5c95c5;
  border-bottom: 1px solid #5c95c5;
}
.dx-treelist-rowsview .dx-row-focused.dx-group-row {
  background-color: #5c95c5;
  color: rgba(255, 255, 255, 0.5);
}
.dx-treelist-rowsview .dx-row-focused.dx-group-row .dx-datagrid-summary-item,
.dx-treelist-rowsview .dx-row-focused.dx-group-row .dx-treelist-group-closed,
.dx-treelist-rowsview .dx-row-focused.dx-group-row .dx-treelist-group-opened {
  color: rgba(255, 255, 255, 0.5);
}
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  background-color: #e6e6e6;
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td.dx-treelist-group-space,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-treelist-group-space,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-treelist-group-space,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-treelist-group-space {
  border-right-color: #e6e6e6;
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td.dx-pointer-events-none,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-pointer-events-none,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-pointer-events-none,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-pointer-events-none {
  border-left-color: #d0e0ed;
  border-right-color: #d0e0ed;
}
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td.dx-focused,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > tr
  > td.dx-focused,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > td.dx-focused,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td.dx-focused {
  background-color: #fff;
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
  > td {
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
  > tr:first-child
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
  > tr:first-child
  > td {
  border-top: 1px solid #e6e6e6;
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
  > tr:last-child
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)
  > tr:last-child
  > td {
  border-bottom: 1px solid #e6e6e6;
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines
  > tr
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-column-lines
  > tr
  > td {
  border-left-color: #d0e0ed;
  border-right-color: #d0e0ed;
}
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines
  > tr
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines
  > td,
.dx-treelist-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines
  > tr
  > td {
  border-bottom-color: #d0e0ed;
}
.dx-treelist-rowsview.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both
  .dx-scrollable-wrapper
  .dx-scrollable-container
  .dx-scrollable-content {
  padding-right: 0;
}
.dx-treelist-rowsview td.dx-validation-pending {
  position: relative;
  padding: 0;
}
.dx-treelist-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-treelist-rowsview td.dx-validation-pending.dx-treelist-invalid {
  padding: 7px 34px 7px 7px;
}
.dx-rtl .dx-treelist-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-rtl .dx-treelist-rowsview td.dx-validation-pending.dx-treelist-invalid,
.dx-rtl.dx-treelist-rowsview td.dx-validation-pending.dx-cell-modified,
.dx-rtl.dx-treelist-rowsview td.dx-validation-pending.dx-treelist-invalid {
  padding-left: 34px;
  padding-right: 7px;
}
.dx-treelist-rowsview td.dx-validation-pending .dx-pending-indicator {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  right: 4px;
  background-color: #fff;
}
.dx-rtl .dx-treelist-rowsview td.dx-validation-pending .dx-pending-indicator,
.dx-rtl.dx-treelist-rowsview td.dx-validation-pending .dx-pending-indicator {
  left: 4px;
  right: auto;
}
.dx-treelist-rowsview
  td.dx-editor-cell
  .dx-texteditor.dx-validation-pending
  .dx-texteditor-input {
  padding-right: 34px;
}
.dx-treelist-rowsview
  td.dx-editor-cell
  .dx-texteditor.dx-validation-pending.dx-rtl
  .dx-texteditor-input {
  padding-right: 7px;
  padding-bottom: 7px;
  padding-left: 34px;
}
.dx-treelist-search-text {
  color: #fff;
  background-color: #337ab7;
}
.dx-treelist-nodata {
  color: rgba(174, 184, 201, 0.85);
  font-size: 17px;
}
.dx-treelist-bottom-load-panel {
  border-top: 1px solid #d0e0ed;
}
.dx-treelist-pager {
  border-top: 3px double #d0e0ed;
}
.dx-treelist-pager.dx-widget {
  color: rgba(68, 81, 103, 0.85);
}
.dx-treelist-pager .dx-navigate-button,
.dx-treelist-pager .dx-page,
.dx-treelist-pager .dx-page-size {
  outline: 0;
}
.dx-treelist-pager.dx-state-focused .dx-navigate-button:focus,
.dx-treelist-pager.dx-state-focused .dx-page-size:focus,
.dx-treelist-pager.dx-state-focused .dx-page:focus {
  outline: #5c95c5 solid 2px;
  outline-offset: -2px;
}
.dx-treelist-summary-item {
  color: rgba(68, 81, 103, 0.7);
}
.dx-treelist-total-footer {
  position: relative;
  border-top: 1px solid #d0e0ed;
}
.dx-treelist-revert-tooltip .dx-overlay-content {
  background-color: #fff;
  min-width: inherit;
}
.dx-treelist-revert-tooltip .dx-revert-button {
  margin: 0 1px;
  background-color: #a10000;
  border-color: #800;
  color: #fff;
}
.dx-treelist-revert-tooltip .dx-revert-button .dx-icon {
  color: #fff;
}
.dx-treelist-revert-tooltip .dx-revert-button.dx-state-hover {
  background-color: #6e0000;
  border-color: #800;
}
.dx-treelist-revert-tooltip .dx-revert-button.dx-state-focused {
  background-color: #500;
  border-color: #800;
}
.dx-treelist-revert-tooltip .dx-revert-button.dx-state-active {
  background-color: #3b0000;
  border-color: #800;
  color: #fff;
}
.dx-treelist-revert-tooltip .dx-revert-button > .dx-button-content {
  padding: 7px;
}
.dx-toolbar-menu-section .dx-treelist-checkbox-size {
  width: 100%;
}
.dx-toolbar-menu-section .dx-treelist-checkbox-size .dx-checkbox-container {
  padding: 14px;
}
.dx-toolbar-menu-section .dx-treelist-checkbox-size .dx-checkbox-text {
  padding-left: 34px;
}
.dx-rtl .dx-toolbar-menu-section .dx-checkbox-text {
  padding-right: 34px;
  padding-left: 27px;
}
.dx-rtl
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused).dx-treelist-group-space {
  border-left-color: rgba(0, 0, 0, 0.04);
  border-right-color: transparent;
}
.dx-rtl
  .dx-treelist-filter-row
  .dx-menu
  .dx-menu-item-has-submenu.dx-menu-item-has-icon
  .dx-icon {
  margin: 0 3px;
}
.dx-rtl
  .dx-treelist-filter-row
  td
  .dx-editor-container
  .dx-filter-range-content {
  padding: 7px 32px 7px 7px;
}
.dx-rtl .dx-treelist-rowsview .dx-selection.dx-row > td,
.dx-rtl .dx-treelist-rowsview .dx-selection.dx-row > tr > td,
.dx-rtl .dx-treelist-rowsview .dx-selection.dx-row:hover > td,
.dx-rtl .dx-treelist-rowsview .dx-selection.dx-row:hover > tr > td {
  border-right-color: #d0e0ed;
}
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row
  > td:not(.dx-focused).dx-treelist-group-space,
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row
  > tr
  > td:not(.dx-focused).dx-treelist-group-space,
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row:hover
  > td:not(.dx-focused).dx-treelist-group-space,
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row:hover
  > tr
  > td:not(.dx-focused).dx-treelist-group-space {
  border-left-color: #e6e6e6;
}
.dx-rtl .dx-treelist-rowsview .dx-selection.dx-row > td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row
  > tr
  > td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row:hover
  > td.dx-pointer-events-none,
.dx-rtl
  .dx-treelist-rowsview
  .dx-selection.dx-row:hover
  > tr
  > td.dx-pointer-events-none {
  border-left-color: #d0e0ed;
}
.dx-rtl
  .dx-treelist-rowsview.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both
  .dx-scrollable-wrapper
  .dx-scrollable-container
  .dx-scrollable-content {
  padding-left: 0;
}
.dx-treelist-rowsview .dx-treelist-empty-space {
  position: relative;
  display: inline-block;
  width: 14px;
  font: 14px/1 DXIcons;
}
.dx-treelist-rowsview .dx-treelist-empty-space::before {
  content: '';
  visibility: hidden;
}
.dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space {
  color: rgba(189, 192, 204, 0.8);
}
.dx-treelist-rowsview .dx-treelist-expanded span {
  font: 18px/18px DXIcons;
  text-align: center;
  cursor: pointer;
}
.dx-treelist-rowsview .dx-treelist-expanded span::before {
  content: '';
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  left: 0;
  margin-left: -5px;
  margin-top: -8px;
}
.dx-treelist-rowsview .dx-treelist-collapsed span {
  font: 18px/18px DXIcons;
  text-align: center;
  cursor: pointer;
}
.dx-treelist-rowsview .dx-treelist-collapsed span::before {
  content: '';
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  left: 0;
  margin-left: -6px;
  margin-top: -8px;
}
.dx-treelist-rowsview
  tr:not(.dx-row-focused).dx-selection
  .dx-treelist-empty-space {
  color: rgba(189, 192, 204, 0.8);
}
.dx-treelist-rowsview .dx-treelist-cell-expandable {
  white-space: nowrap;
}
.dx-treelist-rowsview .dx-treelist-cell-expandable .dx-treelist-text-content {
  white-space: normal;
}
.dx-treelist-rowsview.dx-treelist-nowrap
  .dx-treelist-table
  .dx-treelist-cell-expandable
  .dx-treelist-text-content {
  white-space: nowrap;
}
.dx-treelist-checkbox-size {
  vertical-align: middle;
  line-height: 0;
}
.dx-treelist-cell-expandable .dx-checkbox,
.dx-treelist-select-all .dx-checkbox {
  position: absolute;
}
.dx-treelist-icon-container.dx-editor-inline-block {
  position: relative;
  padding-right: 23px;
}
.dx-treelist-icon-container.dx-editor-inline-block .dx-checkbox {
  top: 50%;
  margin-top: -8px;
}
.dx-treelist-select-all {
  position: relative;
}
.dx-treelist-select-all .dx-checkbox {
  left: 21px;
  margin-top: 1px;
}
.dx-treelist-headers .dx-header-row > .dx-treelist-select-all {
  padding-left: 44px;
}
.dx-rtl .dx-treelist-rowsview .dx-treelist-collapsed,
.dx-rtl .dx-treelist-rowsview .dx-treelist-expanded {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl
  .dx-treelist-rowsview
  .dx-treelist-table-fixed
  .dx-treelist-icon-container {
  float: right;
}
.dx-rtl .dx-treelist-select-all .dx-checkbox {
  left: calc(100% - 37px);
}
.dx-rtl .dx-treelist-headers .dx-header-row > .dx-treelist-select-all {
  padding-right: 44px;
  padding-left: 7px;
}
.dx-rtl .dx-treelist-icon-container.dx-editor-inline-block {
  padding-left: 23px;
  padding-right: 0;
}
.dx-treelist-filter-panel .dx-icon-filter,
.dx-treelist-filter-panel .dx-treelist-filter-panel-clear-filter,
.dx-treelist-filter-panel .dx-treelist-filter-panel-text {
  outline: 0;
  color: #337ab7;
}
.dx-pivotgrid-fields-container .dx-header-filter,
.dx-pivotgrid-fields-container .dx-sort {
  display: inline-block;
}
.dx-pivotgrid-fields-container.dx-drag .dx-area-field-content {
  display: inline-block;
}
.dx-pivotgrid-fields-container.dx-drag .dx-column-indicators {
  float: none;
  display: inline-block;
}
.dx-pivotgrid-nodata {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.dx-pivotgrid {
  cursor: default;
  width: 100%;
  position: relative;
  background-color: #fff;
}
.dx-pivotgrid.dx-overflow-hidden {
  overflow: hidden;
}
.dx-pivotgrid .dx-area-column-cell,
.dx-pivotgrid .dx-area-data-cell {
  width: 100%;
}
.dx-pivotgrid .dx-area-data-cell {
  position: relative;
}
.dx-pivotgrid table,
.dx-pivotgrid tbody,
.dx-pivotgrid td,
.dx-pivotgrid tfoot,
.dx-pivotgrid th,
.dx-pivotgrid thead,
.dx-pivotgrid tr {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
.dx-pivotgrid table {
  border-collapse: collapse;
  table-layout: auto;
  border-spacing: 0;
}
.dx-pivotgrid td {
  vertical-align: top;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-pivotgrid .dx-area-description-cell {
  position: relative;
  background-clip: padding-box;
  white-space: nowrap;
}
.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-fields-area {
  position: absolute;
  bottom: 0;
}
.dx-pivotgrid .dx-ie {
  height: 1px;
}
.dx-pivotgrid .dx-ie .dx-area-description-cell {
  position: static;
  height: inherit;
  vertical-align: bottom;
  font-size: 0;
}
.dx-pivotgrid .dx-ie .dx-area-description-cell .dx-pivotgrid-fields-area {
  position: relative;
  display: inline-block;
}
.dx-pivotgrid .dx-area-field-content,
.dx-pivotgrid .dx-column-indicators {
  display: inline-block;
}
.dx-pivotgrid .dx-expand-icon-container {
  position: relative;
  display: inline-block;
}
.dx-pivotgrid .dx-incompressible-fields .dx-pivotgrid-fields-area {
  position: static;
}
.dx-pivotgrid
  .dx-incompressible-fields
  .dx-pivotgrid-fields-area:not(.dx-hidden) {
  display: table-cell;
}
.dx-pivotgrid .dx-incompressible-fields .dx-column-indicators {
  vertical-align: top;
  float: none !important;
}
.dx-pivotgrid .dx-incompressible-fields .dx-area-field {
  display: inline-block;
  white-space: nowrap;
}
.dx-pivotgrid .dx-area-field,
.dx-pivotgrid .dx-area-field-content {
  white-space: nowrap;
}
.dx-pivotgrid .dx-popup-content .dx-column-indicators {
  float: none !important;
  display: inline-block;
}
.dx-pivotgrid .dx-popup-content .dx-area-field-content {
  display: inline-block;
}
.dx-pivotgrid .dx-pivotgrid-area {
  white-space: nowrap;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.dx-pivotgrid .dx-pivotgrid-collapsed,
.dx-pivotgrid .dx-pivotgrid-expanded {
  cursor: pointer;
}
.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand,
.dx-pivotgrid .dx-pivotgrid-expanded .dx-expand {
  display: inline-block;
}
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-area {
  white-space: normal;
}
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-collapsed,
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-expanded,
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-sorted {
  white-space: nowrap;
}
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-collapsed > span,
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-expanded > span,
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-sorted > span {
  white-space: normal;
}
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-collapsed > span,
.dx-pivotgrid .dx-word-wrap .dx-pivotgrid-expanded > span {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.dx-pivotgridfieldchooser {
  position: relative;
  background-color: #fff;
}
.dx-pivotgridfieldchooser .dx-pivotgridfieldchooser-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
}
.dx-pivotgridfieldchooser .dx-pivotgridfieldchooser-container.dx-layout-0,
.dx-pivotgridfieldchooser .dx-pivotgridfieldchooser-container.dx-layout-2 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dx-pivotgridfieldchooser
  .dx-pivotgridfieldchooser-container.dx-layout-2
  .dx-row:nth-child(1) {
  -webkit-box-flex: 3;
  -webkit-flex-grow: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
}
.dx-pivotgridfieldchooser
  .dx-pivotgridfieldchooser-container.dx-layout-2
  .dx-row:nth-child(2) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 4;
  -webkit-flex-grow: 4;
  -ms-flex-positive: 4;
  flex-grow: 4;
}
.dx-pivotgridfieldchooser .dx-col {
  width: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dx-pivotgridfieldchooser .dx-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 0;
}
.dx-pivotgridfieldchooser
  .dx-pivotgridfieldchooser-container
  .dx-row:nth-child(1) {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.dx-pivotgridfieldchooser
  .dx-pivotgridfieldchooser-container
  .dx-row:nth-child(2) {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.dx-pivotgridfieldchooser .dx-area-caption {
  vertical-align: middle;
}
.dx-pivotgrid-action {
  cursor: pointer;
}
.dx-pivotgrid-drag-action {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.dx-pivotgrid-fields-container
  .dx-position-indicator.dx-position-indicator-vertical {
  margin-top: -4px;
  margin-left: -1px;
  height: 2px;
}
.dx-pivotgrid-fields-container
  .dx-position-indicator.dx-position-indicator-vertical.dx-position-indicator-last {
  margin-top: -3px;
}
.dx-pivotgrid-fields-container
  .dx-position-indicator.dx-position-indicator-horizontal {
  margin-left: -3px;
  width: 2px;
}
.dx-pivotgrid-fields-container
  .dx-position-indicator.dx-position-indicator-horizontal.dx-position-indicator-last {
  margin-left: 3px;
}
.dx-pivotgrid-fields-container .dx-area-fields {
  position: relative;
}
.dx-pivotgrid-fields-container .dx-sort {
  color: #565b6f;
  font-size: 14px;
  width: 14px;
}
.dx-pivotgrid-fields-container .dx-sort-up {
  font: 14px/1 DXIcons;
}
.dx-pivotgrid-fields-container .dx-sort-up::before {
  content: '';
}
.dx-pivotgrid-fields-container .dx-sort-down {
  font: 14px/1 DXIcons;
}
.dx-pivotgrid-fields-container .dx-sort-down::before {
  content: '';
}
.dx-pivotgrid-fields-container .dx-header-filter {
  color: #565b6f;
  font: 14px/1 DXIcons;
  width: 14px;
}
.dx-pivotgrid-fields-container .dx-header-filter::before {
  content: '';
}
.dx-pivotgrid-fields-container .dx-header-filter-empty {
  color: rgba(86, 91, 111, 0.5);
}
.dx-pivotgrid-fields-container .dx-area-field {
  cursor: pointer;
}
.dx-pivotgrid-fields-container.dx-drag {
  opacity: 0.8;
}
.dx-pivotgrid-fields-container.dx-drag .dx-area-field.dx-area-box {
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(51, 122, 183, 0.5);
}
.dx-pivotgrid-fields-container .dx-area-field.dx-area-box {
  background-color: #fff;
  margin-bottom: 4px;
  border: 1px solid #d0e0ed;
  padding: 7px 10px;
}
.dx-pivotgrid-fields-container .dx-drag-source {
  opacity: 0.5;
}
.dx-pivotgrid-fields-container .dx-column-indicators {
  vertical-align: bottom;
  margin-left: 6px;
  line-height: 19px;
}
.dx-pivotgrid-fields-container .dx-area-field-content {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.dx-pivotgrid .dx-column-header .dx-pivotgrid-fields-area,
.dx-pivotgrid .dx-filter-header .dx-pivotgrid-fields-area {
  overflow: hidden;
}
.dx-pivotgrid .dx-column-header .dx-pivotgrid-toolbar,
.dx-pivotgrid .dx-filter-header .dx-pivotgrid-toolbar {
  margin-right: 10px;
  float: right;
  display: inline-block;
}
.dx-pivotgrid
  .dx-column-header
  .dx-pivotgrid-toolbar
  .dx-pivotgrid-field-chooser-button,
.dx-pivotgrid
  .dx-filter-header
  .dx-pivotgrid-toolbar
  .dx-pivotgrid-field-chooser-button {
  margin-right: 4px;
}
.dx-pivotgrid .dx-area-description-cell.dx-pivotgrid-background,
.dx-pivotgrid .dx-column-header,
.dx-pivotgrid .dx-data-header {
  background-color: rgba(221, 221, 221, 0.1);
}
.dx-pivotgrid
  .dx-column-header
  .dx-pivotgrid-fields-area-head
  tr
  > td:first-child {
  padding-left: 0;
}
.dx-pivotgrid .dx-filter-header .dx-area-field-container {
  border-spacing: 8px;
  border-collapse: separate;
}
.dx-pivotgrid .dx-filter-header .dx-pivotgrid-fields-area-head td {
  padding: 2px;
}
.dx-pivotgrid
  .dx-filter-header
  .dx-pivotgrid-fields-area-head
  tr
  > td:first-child {
  padding-left: 2px;
}
.dx-pivotgrid .dx-area-field.dx-area-box {
  margin-bottom: 0;
}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area td {
  border-top: 1px solid #d0e0ed;
}
.dx-pivotgrid.dx-row-lines .dx-pivotgrid-area-data tr:first-child > td {
  border-top-width: 0;
}
.dx-pivotgrid .dx-area-description-cell,
.dx-pivotgrid .dx-area-row-cell {
  border-right: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-area-description-cell .dx-pivotgrid-toolbar .dx-button {
  margin: 1px;
}
.dx-pivotgrid
  .dx-area-description-cell
  .dx-pivotgrid-toolbar
  .dx-button:not(.dx-state-hover):not(.dx-state-active) {
  border-color: transparent;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dx-pivotgrid .dx-area-column-cell,
.dx-pivotgrid .dx-area-description-cell,
.dx-pivotgrid .dx-bottom-border {
  border-bottom: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-area td {
  color: #565b6f;
  padding: 7px 10px;
}
.dx-pivotgrid .dx-pivotgrid-fields-area-head td {
  position: relative;
  border: none;
  padding: 10px 2px;
}
.dx-pivotgrid .dx-pivotgrid-fields-area-head tr > td:first-child {
  padding-left: 10px;
}
.dx-pivotgrid .dx-pivotgrid-fields-area-head tr > td:last-child {
  padding-right: 10px;
}
.dx-pivotgrid .dx-pivotgrid-fields-area-head .dx-empty-area-text {
  white-space: nowrap;
  padding: 6px 0;
  border: 1px solid transparent;
  color: #1c1d24;
}
.dx-pivotgrid .dx-group-connector {
  position: absolute;
  width: 2px;
  top: 50%;
  height: 2px;
  margin-top: -1px;
  background-color: #d0e0ed;
}
.dx-pivotgrid .dx-group-connector.dx-group-connector-prev {
  left: 0;
}
.dx-pivotgrid .dx-group-connector.dx-group-connector-next {
  right: 0;
}
.dx-pivotgrid .dx-virtual-content {
  display: none;
}
.dx-pivotgrid .dx-virtual-mode .dx-virtual-content {
  position: relative;
  overflow: hidden;
  display: block;
}
.dx-pivotgrid .dx-virtual-mode .dx-virtual-content table td {
  color: transparent;
  background-color: transparent !important;
}
.dx-pivotgrid .dx-virtual-mode .dx-virtual-content table td span {
  visibility: hidden;
}
.dx-pivotgrid .dx-virtual-mode table {
  position: absolute;
}
.dx-pivotgrid .dx-pivotgrid-area-data {
  position: relative;
}
.dx-pivotgrid .dx-pivotgrid-area-data tbody td {
  text-align: right;
  color: rgba(68, 81, 103, 0.85);
  white-space: nowrap;
  border-left: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-area-data tbody td:first-child {
  border-left: 0;
}
.dx-pivotgrid .dx-pivotgrid-area-data tbody tr:first-child .dx-grandtotal,
.dx-pivotgrid .dx-pivotgrid-area-data tbody tr:first-child .dx-total {
  border-top-width: 0;
}
.dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-expand-border {
  border-top: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-last-cell {
  border-right: 0;
}
.dx-pivotgrid .dx-pivotgrid-vertical-headers td {
  min-width: 50px;
  border-right: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-vertical-headers tr:first-child td {
  border-top: 0;
}
.dx-pivotgrid
  .dx-pivotgrid-vertical-headers
  .dx-pivotgrid-fields-area-head
  td:last-child {
  border-right: 0;
}
.dx-pivotgrid .dx-pivotgrid-area-data .dx-row-total,
.dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-row-total {
  border-top: 1px solid #d0e0ed;
  border-bottom: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-area-tree-view .dx-total {
  border-bottom: none;
}
.dx-pivotgrid .dx-area-tree-view td.dx-white-space-column {
  border-top: 1px solid transparent;
  background-color: rgba(221, 221, 221, 0.1);
  padding: 0;
  width: 19px;
  min-width: 19px;
}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers.dx-vertical-scroll {
  border-right: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers td {
  text-align: center;
  border: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers td.dx-pivotgrid-collapsed,
.dx-pivotgrid .dx-pivotgrid-horizontal-headers td.dx-pivotgrid-expanded {
  text-align: left;
}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers td:first-child {
  border-left: 0;
}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers tr:first-child td {
  border-top: 0;
}
.dx-pivotgrid .dx-expand-icon-container {
  margin-left: -5px;
  margin-right: 0;
  width: 14px;
}
.dx-pivotgrid .dx-pivotgrid-horizontal-headers:last-child {
  border-bottom: 0;
}
.dx-pivotgrid .dx-area-description-cell,
.dx-pivotgrid .dx-column-header,
.dx-pivotgrid .dx-data-header,
.dx-pivotgrid .dx-total {
  background-color: rgba(221, 221, 221, 0.1);
}
.dx-pivotgrid .dx-grandtotal {
  background-color: #f5f5f5;
}
.dx-pivotgrid .dx-pivotgrid-border .dx-area-description-cell,
.dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell,
.dx-pivotgrid .dx-pivotgrid-border .dx-data-header,
.dx-pivotgrid .dx-pivotgrid-border .dx-filter-header {
  border-left: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-border .dx-area-column-cell,
.dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell,
.dx-pivotgrid .dx-pivotgrid-border .dx-column-header,
.dx-pivotgrid .dx-pivotgrid-border .dx-filter-header {
  border-right: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-border .dx-filter-header {
  border-top: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-pivotgrid-border .dx-filter-header td {
  display: inline-block;
}
.dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell,
.dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell {
  border-bottom: 1px solid #d0e0ed;
}
.dx-pivotgrid .dx-icon-sorted {
  display: inline-block;
  margin-left: 5px;
  width: 14px;
}
.dx-pivotgrid .dx-popup-content {
  padding: 10px;
}
.dx-pivotgrid .dx-popup-content .dx-pivotgrid-fields-area-head td {
  padding: 0 2px;
}
.dx-pivotgridfieldchooser .dx-area-fields {
  overflow: hidden;
}
.dx-pivotgridfieldchooser .dx-treeview-item .dx-icon {
  margin-bottom: 1px;
  width: 16px;
  height: 16px;
  background-position: 0 0;
  background-size: 16px 16px;
  padding: 0;
  font-size: 16px;
  text-align: center;
  line-height: 16px;
}
.dx-pivotgridfieldchooser .dx-treeview-search {
  margin: 5px;
}
.dx-pivotgridfieldchooser .dx-area-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
.dx-pivotgridfieldchooser .dx-area {
  padding: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dx-pivotgridfieldchooser .dx-area > .dx-scrollable,
.dx-pivotgridfieldchooser .dx-area > .dx-scrollable > .dx-scrollable-wrapper,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper,
.dx-pivotgridfieldchooser .dx-area > .dx-treeview,
.dx-pivotgridfieldchooser .dx-area > .dx-treeview > .dx-scrollable,
.dx-pivotgridfieldchooser .dx-area > .dx-treeview > .dx-scrollable-wrapper,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > .dx-scrollable
  > .dx-scrollable-wrapper,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > div
  > .dx-scrollable-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 0;
}
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-scrollable
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > .dx-scrollable
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > .dx-scrollable
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > .dx-scrollable-wrapper
  > .dx-scrollable-container,
.dx-pivotgridfieldchooser
  .dx-area
  > .dx-treeview
  > div
  > .dx-scrollable-wrapper
  > .dx-scrollable-container {
  height: auto;
}
.dx-pivotgridfieldchooser .dx-area .dx-area-fields {
  margin-top: 3px;
  border: 1px solid #d0e0ed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 0;
}
.dx-pivotgridfieldchooser .dx-area-fields-header .dx-area-caption {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.dx-pivotgridfieldchooser .dx-area-fields[group] {
  padding: 5px;
  background-color: rgba(221, 221, 221, 0.1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dx-pivotgridfieldchooser .dx-area-fields.dx-drag-target {
  border-color: #337ab7;
}
.dx-pivotgridfieldchooser .dx-area-icon-all {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAJElEQVQoz2P8z4AfsDAwJELVzGfExmIiYAAD5QoYRx1JL0cCAJeiFh8Qq9chAAAAAElFTkSuQmCC)
    center center no-repeat;
}
.dx-pivotgridfieldchooser .dx-area-icon-filter {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAWElEQVQoz83RsQqAMAxF0fepFxzEQRz8e1sah0JTamhXeVtyCCSRaR6ZTGQsSHJgcRyk1YQ7aBcuB+KkDO0D9UDsHcmARiC2BqiVEfg2+jOoF30+YPnNWV4jV/jo04VE6gAAAABJRU5ErkJggg==)
    center center no-repeat;
}
.dx-pivotgridfieldchooser .dx-area-icon-row {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAI0lEQVQoz2P4z4AfAlHCfwjEzqKPAsKObIBA7Cz6KBgGIQkAQ8IdQJKOGQIAAAAASUVORK5CYII=)
    center center no-repeat;
}
.dx-pivotgridfieldchooser .dx-area-icon-column {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAIElEQVQoz2P4z4AfAlHCfwgEshogEFmMPgpGHUkfRwIAQ8IdQALkrHMAAAAASUVORK5CYII=)
    center center no-repeat;
}
.dx-pivotgridfieldchooser .dx-area-icon-data,
.dx-pivotgridfieldchooser .dx-icon-measure {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAARElEQVQoz2P4z4AfMhClIOE/NkiSAl+ooG8CQwKIzwChEQpQlGBXgKYEwxeoSrB6k7ACfFYkYPgDXQGKdAItQpKi2AQAaDQFJxj4SdQAAAAASUVORK5CYII=)
    center center no-repeat;
}
.dx-pivotgridfieldchooser .dx-icon-dimension {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAH0lEQVQoz2P4z4AfMlBHQcJ/MESjqasAKxx5bqAosgCZ3QSYpC33dQAAAABJRU5ErkJggg==)
    center center no-repeat;
}
.dx-pivotgridfieldchooser .dx-icon-hierarchy {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAMUlEQVQoz2P4z4AfMlBXQcJ/EKShggQoxKEAojsBwxQqKUjACpEVoOhGNYVKCiiKLAATcARoA49V5wAAAABJRU5ErkJggg==)
    center center no-repeat;
}
.dx-rtl
  .dx-pivotgrid-fields-container
  .dx-position-indicator.dx-position-indicator-horizontal {
  margin-left: -3px;
}
.dx-rtl
  .dx-pivotgrid-fields-container
  .dx-position-indicator.dx-position-indicator-horizontal.dx-position-indicator-last {
  margin-left: 1px;
}
.dx-rtl .dx-pivotgrid-fields-container .dx-column-indicators {
  margin-left: 0;
  margin-right: 6px;
}
.dx-rtl.dx-pivotgrid .dx-column-header .dx-pivotgrid-toolbar,
.dx-rtl.dx-pivotgrid .dx-filter-header .dx-pivotgrid-toolbar {
  margin-right: 0;
  margin-left: 10px;
  float: left;
}
.dx-rtl.dx-pivotgrid
  .dx-column-header
  .dx-pivotgrid-toolbar
  .dx-pivotgrid-field-chooser-button,
.dx-rtl.dx-pivotgrid
  .dx-filter-header
  .dx-pivotgrid-toolbar
  .dx-pivotgrid-field-chooser-button {
  margin-right: 0;
  margin-left: 4px;
}
.dx-rtl.dx-pivotgrid
  .dx-column-header
  .dx-pivotgrid-fields-area-head
  tr
  > td:first-child {
  padding-left: 2px;
  padding-right: 0;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-fields-area-head tr > td {
  padding: 10px 2px;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-fields-area-head tr > td:first-child {
  padding-right: 10px;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-fields-area-head tr > td:last-child {
  padding-left: 10px;
}
.dx-rtl.dx-pivotgrid .dx-group-connector.dx-group-connector-prev {
  left: initial;
  right: 0;
}
.dx-rtl.dx-pivotgrid .dx-group-connector.dx-group-connector-next {
  right: initial;
  left: 0;
}
.dx-rtl.dx-pivotgrid .dx-area-description-cell,
.dx-rtl.dx-pivotgrid .dx-area-row-cell {
  border-left: 1px solid #d0e0ed;
  border-right: 0;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-area-data tbody td {
  border-left: 0;
  border-right: 1px solid #d0e0ed;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-area-data tbody td:first-child {
  border-left: 1px solid #d0e0ed;
  border-right: 0;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-vertical-headers td {
  border-right: 0;
  border-left: 1px solid #d0e0ed;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-last-cell {
  border-left: 0;
  border-right: 0;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-horizontal-headers.dx-vertical-scroll {
  border-right: 0;
  border-left: 1px solid #d0e0ed;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-horizontal-headers.dx-pivotgrid-area {
  border-left: 0;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-horizontal-headers td:first-child {
  border-left: 1px solid #d0e0ed;
  border-right: 0;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-horizontal-headers td.dx-pivotgrid-collapsed,
.dx-rtl.dx-pivotgrid .dx-pivotgrid-horizontal-headers td.dx-pivotgrid-expanded {
  text-align: right;
}
.dx-rtl.dx-pivotgrid .dx-expand-icon-container {
  margin-left: 0;
  margin-right: -5px;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand-icon-container {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dx-rtl.dx-pivotgrid .dx-icon-sorted {
  margin-left: 0;
  margin-right: 5px;
}
.dx-rtl.dx-pivotgrid .dx-pivotgridfieldchooser-container .dx-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-area-description-cell,
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell,
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-data-header {
  border-right: 1px solid #d0e0ed;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-area-column-cell,
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-area-data-cell,
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-column-header {
  border-left: 1px solid #d0e0ed;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-column-header {
  border-right: none;
}
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-area-row-cell,
.dx-rtl.dx-pivotgrid .dx-pivotgrid-border .dx-data-header {
  border-left: none;
}
.dx-pivotgrid .dx-area-description-cell .dx-button-content,
.dx-pivotgrid .dx-column-header .dx-pivotgrid-toolbar .dx-button-content,
.dx-pivotgrid .dx-filter-header .dx-pivotgrid-toolbar .dx-button-content {
  padding: 5px;
}
.dx-pivotgrid .dx-column-header .dx-pivotgrid-toolbar .dx-button,
.dx-pivotgrid .dx-filter-header .dx-pivotgrid-toolbar .dx-button {
  margin-top: 10px;
}
.dx-pivotgrid .dx-expand-icon-container {
  font: 14px/1 DXIcons;
}
.dx-pivotgrid .dx-expand-icon-container::before {
  content: '';
  visibility: hidden;
}
.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand {
  font: 18px/18px DXIcons;
  text-align: center;
  color: rgba(189, 192, 204, 0.8);
}
.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand::before {
  content: '';
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  margin-top: -9px;
  left: 50%;
  margin-left: -9px;
}
.dx-pivotgrid .dx-pivotgrid-expanded .dx-expand {
  font: 18px/18px DXIcons;
  text-align: center;
  color: rgba(189, 192, 204, 0.8);
}
.dx-pivotgrid .dx-pivotgrid-expanded .dx-expand::before {
  content: '';
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  margin-top: -9px;
  left: 50%;
  margin-left: -9px;
}
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area {
  color: rgba(68, 81, 103, 0.85);
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto;
  line-height: 1.35715;
}
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area input,
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area textarea {
  font-family: Roboto;
  line-height: 1.35715;
}
.dx-pivotgrid-fields-container .dx-position-indicator {
  background-color: gray;
}
.dx-context-menu.dx-overlay-content {
  overflow: inherit;
  position: absolute;
}
.dx-context-menu .dx-menu-items-container {
  padding: 1px;
}
.dx-context-menu .dx-menu-item .dx-submenu {
  position: absolute;
  z-index: 1003;
}
.dx-context-menu {
  color: rgba(68, 81, 103, 0.85);
}
.dx-context-menu .dx-submenu {
  background-color: #fff;
  border: 1px solid #d0e0ed;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}
.dx-context-menu .dx-menu-item-popout {
  font: 18px/18px DXIcons;
  text-align: center;
}
.dx-context-menu .dx-menu-item-popout::before {
  content: '';
  position: absolute;
  display: block;
  width: 18px;
  top: 50%;
  margin-top: -9px;
  left: 50%;
  margin-left: -9px;
}
.dx-context-menu .dx-menu-separator {
  height: 1px;
  margin: 5px 0;
  background-color: #d0e0ed;
}
.dx-context-menu
  .dx-menu-no-icons
  > .dx-menu-item-wrapper
  > .dx-menu-item
  > .dx-menu-item-content
  .dx-menu-item-text {
  padding-left: 5px;
}
.dx-rtl
  .dx-context-menu
  .dx-menu-no-icons
  > .dx-menu-item-wrapper
  > .dx-menu-item
  > .dx-menu-item-content
  .dx-menu-item-text,
.dx-rtl.dx-context-menu
  .dx-menu-no-icons
  > .dx-menu-item-wrapper
  > .dx-menu-item
  > .dx-menu-item-content
  .dx-menu-item-text {
  padding-right: 5px;
  padding-left: 25px;
}
.dx-context-menu.dx-rtl .dx-menu-item-content {
  padding: 5px 3px 5px 5px;
}
.dx-context-menu.dx-rtl .dx-menu-item-content .dx-menu-item-text {
  padding: 0 23px 5px 25px;
}
.dx-multiview-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -ms-touch-action: pinch-zoom pan-y;
  touch-action: pinch-zoom pan-y;
}
.dx-multiview-item-container {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
}
.dx-multiview-item-container .dx-empty-message {
  text-align: center;
}
.dx-multiview-item {
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100%;
}
.dx-multiview-item.dx-item-selected {
  position: relative;
}
.dx-multiview-item-content {
  width: 100%;
  height: 100%;
}
.dx-multiview-item-hidden {
  top: -9999px;
  visibility: hidden;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs {
  display: block;
  border: none;
  background-color: #f7f7f7;
  color: #d0e0ed;
  -webkit-box-shadow: inset 0 -1px #d0e0ed, inset 0 1px, inset -1px 0,
    inset 1px 0;
  box-shadow: inset 0 -1px #d0e0ed, inset 0 1px, inset -1px 0, inset 1px 0;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs .dx-tab {
  color: #d0e0ed;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs .dx-tab .dx-tab-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs .dx-tab::after,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs .dx-tab::before {
  content: none;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs .dx-tabs-nav-button {
  color: #d0e0ed;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  width: 140px;
  -webkit-box-shadow: inset 0 1px, inset 0 -1px #d0e0ed;
  box-shadow: inset 0 1px, inset 0 -1px #d0e0ed;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-scrollable {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-scrollable .dx-tabs-wrapper {
  display: block;
  border: none;
}
.dx-tabpanel:not(.dx-empty-collection) .dx-multiview-wrapper {
  border-top: none;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
  -webkit-box-shadow: inset 0 1px #d0e0ed, inset -1px 0 #d0e0ed,
    inset 1px 0 #d0e0ed;
  box-shadow: inset 0 1px #d0e0ed, inset -1px 0 #d0e0ed, inset 1px 0 #d0e0ed;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-active .dx-tab-content,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-tabpanel
  > .dx-tabpanel-tabs
  .dx-tabs-expanded
  .dx-tab:not(.dx-tab-selected):first-of-type {
  -webkit-box-shadow: inset 0 -1px #d0e0ed, inset 1px 0, inset 0 1px;
  box-shadow: inset 0 -1px #d0e0ed, inset 1px 0, inset 0 1px;
}
.dx-rtl
  .dx-tabpanel
  > .dx-tabpanel-tabs
  .dx-tabs-expanded
  .dx-tab:not(.dx-tab-selected):first-of-type,
.dx-rtl.dx-tabpanel
  > .dx-tabpanel-tabs
  .dx-tabs-expanded
  .dx-tab:not(.dx-tab-selected):first-of-type,
.dx-tabpanel
  > .dx-tabpanel-tabs
  .dx-tabs-stretched
  .dx-tab:not(.dx-tab-selected):last-of-type {
  -webkit-box-shadow: inset 0 -1px #d0e0ed, inset -1px 0, inset 0 1px;
  box-shadow: inset 0 -1px #d0e0ed, inset -1px 0, inset 0 1px;
}
.dx-rtl
  .dx-tabpanel
  > .dx-tabpanel-tabs
  .dx-tabs-stretched
  .dx-tab:not(.dx-tab-selected):last-of-type,
.dx-rtl.dx-tabpanel
  > .dx-tabpanel-tabs
  .dx-tabs-stretched
  .dx-tab:not(.dx-tab-selected):last-of-type,
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-nav-button-left {
  -webkit-box-shadow: inset 0 -1px #d0e0ed, inset 1px 0, inset 0 1px;
  box-shadow: inset 0 -1px #d0e0ed, inset 1px 0, inset 0 1px;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-nav-button-right {
  -webkit-box-shadow: inset 0 -1px #d0e0ed, inset 0 1px, inset -1px 0;
  box-shadow: inset 0 -1px #d0e0ed, inset 0 1px, inset -1px 0;
}
.dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tabs {
  -webkit-box-shadow: inset 0 -1px #337ab7, inset 0 1px, inset -1px 0,
    inset 1px 0;
  box-shadow: inset 0 -1px #337ab7, inset 0 1px, inset -1px 0, inset 1px 0;
}
.dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tab {
  -webkit-box-shadow: inset 0 1px, inset 0 -1px #337ab7;
  box-shadow: inset 0 1px, inset 0 -1px #337ab7;
}
.dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
  -webkit-box-shadow: inset 0 1px #337ab7, inset -1px 0 #337ab7,
    inset 1px 0 #337ab7;
  box-shadow: inset 0 1px #337ab7, inset -1px 0 #337ab7, inset 1px 0 #337ab7;
}
.dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tab.dx-tab-active
  .dx-tab-content,
.dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tab.dx-tab-selected
  .dx-tab-content {
  color: rgba(68, 81, 103, 0.85);
}
.dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tabs-expanded
  .dx-tab:not(.dx-tab-selected):first-of-type {
  -webkit-box-shadow: inset 0 -1px #337ab7, inset 1px 0, inset 0 1px;
  box-shadow: inset 0 -1px #337ab7, inset 1px 0, inset 0 1px;
}
.dx-rtl
  .dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tabs-expanded
  .dx-tab:not(.dx-tab-selected):first-of-type,
.dx-rtl.dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tabs-expanded
  .dx-tab:not(.dx-tab-selected):first-of-type,
.dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tabs-stretched
  .dx-tab:not(.dx-tab-selected):last-of-type {
  -webkit-box-shadow: inset 0 -1px #337ab7, inset -1px 0, inset 0 1px;
  box-shadow: inset 0 -1px #337ab7, inset -1px 0, inset 0 1px;
}
.dx-rtl
  .dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tabs-stretched
  .dx-tab:not(.dx-tab-selected):last-of-type,
.dx-rtl.dx-tabpanel.dx-state-focused
  > .dx-tabpanel-tabs
  .dx-tabs-stretched
  .dx-tab:not(.dx-tab-selected):last-of-type,
.dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tabs-nav-button-left {
  -webkit-box-shadow: inset 0 -1px #337ab7, inset 1px 0, inset 0 1px;
  box-shadow: inset 0 -1px #337ab7, inset 1px 0, inset 0 1px;
}
.dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tabs-nav-button-right {
  -webkit-box-shadow: inset 0 -1px #337ab7, inset 0 1px, inset -1px 0;
  box-shadow: inset 0 -1px #337ab7, inset 0 1px, inset -1px 0;
}
.dx-tabpanel.dx-state-focused .dx-multiview-wrapper {
  border-color: #337ab7;
}
.dx-tabpanel .dx-multiview-wrapper {
  border: 1px solid #d0e0ed;
}
.dx-layout-manager .dx-field-item:not(.dx-first-row) {
  padding-top: 10px;
}
.dx-layout-manager .dx-field-item:not(.dx-first-col) {
  padding-left: 15px;
}
.dx-layout-manager .dx-field-item:not(.dx-last-col) {
  padding-right: 15px;
}
.dx-layout-manager .dx-field-empty-item {
  width: 100%;
}
.dx-layout-manager.dx-layout-manager-one-col
  .dx-single-column-item-content
  > .dx-field-item {
  padding-left: 0;
  padding-right: 0;
}
.dx-layout-manager.dx-layout-manager-one-col
  .dx-form-group
  .dx-single-column-item-content
  > .dx-first-row.dx-col-0.dx-field-item {
  padding-top: 0;
}
.dx-layout-manager.dx-layout-manager-one-col
  .dx-box-item:not(:first-child)
  .dx-single-column-item-content
  > .dx-field-item {
  padding-top: 10px;
}
.dx-layout-manager .dx-label-h-align.dx-flex-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-label {
  display: block;
}
.dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-content,
.dx-layout-manager
  .dx-label-h-align.dx-flex-layout
  .dx-field-item-content-wrapper {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  display: block;
}
.dx-layout-manager
  .dx-label-h-align.dx-flex-layout:not(.dx-field-item-label-align) {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
.dx-layout-manager
  .dx-label-h-align.dx-field-item-label-align:not(.dx-flex-layout)
  .dx-field-item-label {
  vertical-align: baseline;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-content,
.dx-layout-manager .dx-label-h-align .dx-field-item-content-wrapper,
.dx-layout-manager .dx-label-h-align .dx-field-item-label {
  display: table-cell;
}
.dx-layout-manager
  .dx-label-h-align
  .dx-field-item-content-wrapper
  .dx-field-item-content {
  display: block;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-label {
  white-space: nowrap;
  vertical-align: middle;
}
.dx-layout-manager
  .dx-label-h-align
  .dx-field-item-label
  .dx-field-item-label-content {
  display: block;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-content {
  vertical-align: top;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-content .dx-checkbox,
.dx-layout-manager .dx-label-h-align .dx-field-item-content .dx-switch {
  margin-top: 7px;
  margin-bottom: 4px;
}
.dx-layout-manager .dx-label-h-align .dx-field-item-content,
.dx-layout-manager .dx-label-h-align .dx-field-item-content-wrapper {
  width: 100%;
}
.dx-layout-manager .dx-tabpanel .dx-multiview-item-content {
  padding: 20px;
}
.dx-field-item-label-location-top {
  display: block;
}
.dx-form-group-content {
  border-width: 0;
  padding: 0;
  margin: 0;
}
.dx-form-group-with-caption > .dx-form-group-content {
  padding-top: 19px;
  margin-top: 6px;
  border-top: 1px solid #d0e0ed;
  padding-bottom: 20px;
}
.dx-form-group-with-caption .dx-form-group.dx-form-group-with-caption {
  padding-left: 20px;
}
.dx-layout-manager-hidden-label {
  position: absolute;
  display: block;
  visibility: hidden;
}
.dx-field-item-help-text {
  font-style: italic;
  white-space: normal;
}
.dx-field-item-label-location-left {
  padding-right: 10px;
}
.dx-field-item-label-location-right {
  padding-left: 10px;
}
.dx-rtl .dx-field-item-optional-mark,
.dx-rtl .dx-field-item-required-mark {
  unicode-bidi: embed;
}
.dx-rtl .dx-field-item:not(.dx-first-col) {
  padding-left: 0;
  padding-right: 15px;
}
.dx-rtl .dx-field-item:not(.dx-last-col) {
  padding-left: 15px;
  padding-right: 0;
}
.dx-rtl .dx-field-item-label-location-left {
  padding-right: 0;
  padding-left: 10px;
}
.dx-rtl .dx-field-item-label-location-right {
  padding-left: 0;
  padding-right: 10px;
}
.dx-rtl
  .dx-layout-manager-one-col
  .dx-single-column-item-content
  > .dx-field-item {
  padding-right: 0;
  padding-left: 0;
}
.dx-rtl .dx-form-group-with-caption .dx-form-group.dx-form-group-with-caption {
  padding-left: 0;
  padding-right: 20px;
}
.dx-form-group-caption {
  font-size: 20px;
}
.dx-field-item-help-text,
.dx-field-item-label-text {
  color: rgba(68, 81, 103, 0.85);
}
.dx-field-item-required-mark {
  color: #6e0000;
}
.dx-field-item-optional-mark {
  color: rgba(200, 207, 219, 0.85);
}
.dx-filterbuilder.dx-rtl .dx-filterbuilder-action-icon,
.dx-filterbuilder.dx-rtl .dx-filterbuilder-text {
  margin-left: 5px;
  margin-right: 0;
}
.dx-filterbuilder.dx-rtl
  .dx-filterbuilder-group
  .dx-filterbuilder-group-content
  .dx-filterbuilder-group-content {
  padding-left: 0;
  padding-right: 26px;
}
.dx-filterbuilder .dx-filterbuilder-action-icon,
.dx-filterbuilder .dx-filterbuilder-text {
  margin-left: 0;
  margin-right: 5px;
}
.dx-filterbuilder .dx-filterbuilder-action-icon:focus,
.dx-filterbuilder .dx-filterbuilder-item-value-text:focus,
.dx-filterbuilder .dx-filterbuilder-text:focus {
  outline: 0;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item {
  display: inline-block;
  white-space: nowrap;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text {
  display: inline-block;
  cursor: pointer;
  white-space: pre;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text
  .dx-filterbuilder-range {
  white-space: nowrap;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text
  .dx-filterbuilder-range-separator,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text
  .dx-filterbuilder-text-separator {
  margin: 0 7px;
  opacity: 0.3;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text
  .dx-filterbuilder-item-value-text,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-group-operation,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-item-field,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text.dx-filterbuilder-item-operation {
  min-width: 20px;
  padding: 2px 7px 3px;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-text
  .dx-texteditor {
  vertical-align: middle;
  margin-top: -7px;
  margin-bottom: -4px;
  display: inline-block;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-action {
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-item
  .dx-filterbuilder-action.dx-filterbuilder-action-icon {
  padding: 5px 8px;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-group-content
  .dx-filterbuilder-group-content {
  padding-left: 26px;
}
.dx-filterbuilder-operations .dx-treeview-item {
  padding-left: 5px;
}
.dx-filterbuilder-operations .dx-rtl .dx-treeview-item {
  padding-right: 5px;
}
.dx-filterbuilder-overlay {
  white-space: nowrap;
}
.dx-filterbuilder-overlay .dx-treeview .dx-treeview-node {
  padding-left: 0;
}
.dx-filterbuilder-overlay
  .dx-treeview
  .dx-treeview-node
  .dx-treeview-node-container-opened {
  margin-left: 15px;
}
.dx-filterbuilder-overlay .dx-treeview.dx-rtl .dx-treeview-node {
  padding-right: 0;
}
.dx-filterbuilder-overlay
  .dx-treeview.dx-rtl
  .dx-treeview-node
  .dx-treeview-node-container-opened {
  margin-right: 15px;
}
.dx-filterbuilder-overlay .dx-overlay-content.dx-popup-normal {
  border-radius: 0;
}
.dx-filterbuilder-overlay
  .dx-overlay-content.dx-popup-normal
  > .dx-popup-content {
  padding: 0;
}
.dx-filterbuilder-overlay .dx-scrollable-container {
  max-height: 360px;
}
.dx-filterbuilder-overlay .dx-menu-items-container {
  padding: 0;
}
.dx-filterbuilder {
  background-color: #fff;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-text {
  color: rgba(68, 81, 103, 0.85);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-group-operation {
  background-color: rgba(161, 0, 0, 0.3);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-group-operation:hover {
  background-color: rgba(161, 0, 0, 0.5);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-group-operation.dx-state-active,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-group-operation:focus {
  background-color: #a10000;
  color: #fff;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-field {
  background-color: rgba(51, 122, 183, 0.3);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-field:hover {
  background-color: rgba(51, 122, 183, 0.5);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-field.dx-state-active,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-field:focus {
  background-color: #337ab7;
  color: #fff;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-operation {
  background-color: rgba(126, 211, 33, 0.3);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-operation:hover {
  background-color: rgba(126, 211, 33, 0.5);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-operation.dx-state-active,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text.dx-filterbuilder-item-operation:focus {
  background-color: #7ed321;
  color: #fff;
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text
  .dx-filterbuilder-item-value-text,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text
  .dx-filterbuilder-item-value-text:hover {
  background-color: rgba(208, 224, 237, 0.5);
}
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text
  .dx-filterbuilder-item-value-text.dx-state-active,
.dx-filterbuilder
  .dx-filterbuilder-group
  .dx-filterbuilder-text
  .dx-filterbuilder-item-value-text:focus {
  background-color: #d0e0ed;
  color: #fff;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus {
  color: rgba(126, 211, 33, 0.3);
}
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus:hover {
  color: rgba(126, 211, 33, 0.5);
}
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus.dx-state-active,
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus:focus {
  color: #7ed321;
}
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove {
  color: rgba(161, 0, 0, 0.3);
}
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove:hover {
  color: rgba(161, 0, 0, 0.5);
}
.dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove:focus {
  color: #a10000;
}
.dx-filterbuilder-overlay.dx-filterbuilder-operations
  .dx-treeview-item
  .dx-icon {
  color: #afb3c1;
  width: 14px;
  height: 14px;
  background-position: 0 0;
  background-size: 14px 14px;
  padding: 0;
  font-size: 14px;
  text-align: center;
  line-height: 14px;
}
.dx-filterbuilder .dx-filterbuilder-action-icon {
  margin-bottom: 9px;
}
.dx-filterbuilder .dx-filterbuilder-item-value-text,
.dx-filterbuilder .dx-filterbuilder-text {
  border-radius: 5px;
}
.dx-filterbuilder .dx-filterbuilder-range-separator {
  color: rgba(68, 81, 103, 0.85);
}
.dx-filterbuilder-overlay .dx-treeview-item {
  padding: 5px 15px;
}
.dx-filterbuilder-overlay .dx-rtl .dx-treeview-item {
  padding-left: 15px;
}
.dx-sortable-placeholder {
  border-color: #337ab7;
}
.dx-sortable-dragging > * {
  border-color: rgba(51, 122, 183, 0.5);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 8px rgba(0, 0, 0, 0.2);
}
